import React, { Component } from "react";
import { PDescription } from "@lucio-erasmus/tfgj-components";
import ProductSelection from "./ProductSelection";

class ProductSelector extends Component {
  getAddedProduct = c => {
    const { product } = this.props;

    switch (c.type) {
      case "name":
      case "engraveTranslate":
      case "engrave": {
        const e = product.find(p => c.sku === p.sku);

        if (e) {
          return (
            <div>
              <PDescription>
                {e.group}: '{e.value}'
              </PDescription>
              {e.font ? (
                <PDescription>
                  Font: {c.fonts.find(f => f.id === e.font).title}
                </PDescription>
              ) : null}
              {e.language ? (
                <PDescription>
                  Language:{" "}
                  {c.languages.find(l => l.code === e.language).language}
                </PDescription>
              ) : null}
            </div>
          );
        }

        return null;
      }
      case "styles": {
        const o = product.find(p => c.sku === p.sku);

        const extra = c.styles.map(o => {
          const e = product.find(p => o.sku === p.sku);
          if (e) {
            return <ProductSelection {...e} />;
          }
          return null;
        });

        if (o) {
          return (
            <div>
              <ProductSelection {...o} connected={c.styles.map(s => s.sku)} />
              {extra}
            </div>
          );
        }

        return null;
      }
      case "singleText": {
        const e = product.find(p => c.sku === p.sku);
        if (e) {
          return <ProductSelection {...e} />;
        }
        return null;
      }
      case "single": {
        const e = product.find(p => c.sku === p.sku);
        if (e) {
          return <ProductSelection {...e} />;
        }
        return null;
      }
      case "additional":
      case "selection": {
        return c.options.map(o => {
          const e = product.find(p => o.sku === p.sku);
          if (e) {
            return this.getAddedProduct(o);
          }

          return null;
        });
      }
      default:
        return null;
    }
  };

  getAddedProductData = c => {
    const { product } = this.props;

    switch (c.type) {
      case "upload":
      case "name":
      case "engraveTranslate":
      case "engrave": {
        const e = product.find(p => c.sku === p.sku);

        if (e) {
          return true;
        }

        return false;
      }
      case "styles": {
        const o = product.find(p => c.sku === p.sku);

        let hasStyles = false;

        c.styles.forEach(o => {
          const e = product.find(p => o.sku === p.sku);
          if (e) {
            hasStyles = true;
          }
        });

        if (o) {
          hasStyles = true;
        }

        return hasStyles;
      }
      case "singleText": {
        const e = product.find(p => c.sku === p.sku);
        if (e) {
          return true;
        }
        return false;
      }
      case "single": {
        const e = product.find(p => c.sku === p.sku);
        if (e) {
          return true;
        }
        return false;
      }
      case "additional":
      case "selection": {
        let hasSelection = false;

        c.options.forEach(o => {
          const e = product.find(p => o.sku === p.sku);
          if (e) {
            hasSelection = this.getAddedProductData(o);
          }
        });

        return hasSelection;
      }
      default:
        return null;
    }
  };

  render() {
    if (this.props.render) {
      return this.props.render(this.getAddedProductData(this.props.item));
    }

    return this.getAddedProduct(this.props.item);
  }
}

export default ProductSelector;
