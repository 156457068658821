import { Loading } from "@lucio-erasmus/tfgj-components";
import React, { useState, useEffect } from "react";

const { screenshot } = require("../v3d/config");

const RingImage = props => {
  const [image, setImage] = useState(null);

  useEffect(() => {
    async function captureImage() {
      const capture = await screenshot();
      if (capture) {
        setImage(capture);
      }
    }
    // Execute the created function directly
    captureImage();
  }, []);

  if (!image) return <Loading minHeight={false} />;

  return (
    <div className="col-md-6 mb-2">
      <img src={image} className="img-thumbnail" alt="" />
    </div>
  );
};

export default RingImage;
