import React, { Component } from "react";
import {
  Alert,
  Copy,
  PBody,
  PDescription,
  PModule,
  PTabs,
  PTab,
  POptionsGrid,
  POptionText
} from "@lucio-erasmus/tfgj-components";
import { ProductContext } from "../Store/CartContext";

class SingleComponentText extends Component {
  onItemClick = item => {
    this.context.onProductAdd({
      ...item,
      sku: this.props.sku,
      group: this.props.group,
      price: item.price || this.props.price,
      type: this.props.type,
      parent: this.props.parentSku
    });
  };

  onDeleteClick = item => {
    this.context.onProductDelete({
      ...item,
      sku: this.props.sku
    });
  };

  render() {
    const {
      name,
      subtitle,
      description,
      note,
      empty,
      className = "",
      groups = [],
      items = [],
      onClose,
      errors = [],
      product = [],
      componentDescription,
      sku
    } = this.props;

    const productConfig = product;

    const activeItem = product.find(i => i.sku === this.props.sku);

    const filteredItems = items.filter(item => {
      if (item.exclusions && item.exclusions.length) {
        return item.exclusions.some(exclude => {
          const currentItem = productConfig.find(i => i.sku === exclude.key);

          if (!currentItem) return false;

          return !(currentItem && exclude.values.includes(currentItem.id));
        });
      }

      return true;
    });

    const desc =
      componentDescription.sku === sku
        ? componentDescription.description
        : description;

    return (
      <div className={className}>
        <PModule title={name} subtitle={subtitle} parent onClose={onClose}>
          <PBody active hasPadding>
            {desc && <PDescription html={desc} />}

            {groups && groups.length ? (
              <PTabs hasMargin>
                {groups.map((g, index) => (
                  <PTab key={index}>{g}</PTab>
                ))}
              </PTabs>
            ) : null}
            <POptionsGrid>
              {!filteredItems.length ? (
                <Alert size="tiny" mb="small" status={"warning"} close={null}>
                  {empty}
                </Alert>
              ) : (
                filteredItems.map((i, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        className
                          ? className
                              .split(" ")
                              .map(r => `${r}--text`)
                              .join(" ")
                          : ""
                      }
                    >
                      <POptionText
                        active={activeItem && activeItem.id === i.id}
                        onClick={() => this.onItemClick(i)}
                        onRemove={() => this.onDeleteClick(i)}
                        title={i.name}
                      >
                        {i.name}
                      </POptionText>
                    </div>
                  );
                })
              )}
              {errors.length ? (
                <div className="w-100">
                  <Copy size="tiny" mb="none" color={"error"}>
                    {errors}
                  </Copy>
                </div>
              ) : null}
            </POptionsGrid>
            {activeItem && activeItem.description ? (
              <PDescription size="tiny" html={activeItem.description} />
            ) : null}
            {note && <PDescription size="tiny" html={note} />}
          </PBody>
        </PModule>
      </div>
    );
  }
}

SingleComponentText.contextType = ProductContext;
export default SingleComponentText;
