import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";
import {
  POptionImage,
  PDescription,
  Copy,
  Loading,
  variables
} from "@lucio-erasmus/tfgj-components";
import { useDropzone } from "react-dropzone";
const { spacing } = variables;

const uploadUrl =
  process.env.REACT_APP_CONVERSION_URL || "http://localhost:5000";

const OptionUpload = (
  { label, className, onLoad, userImg, ...restProps },
  context
) => {
  const [loading, setLoading] = useState(false);

  const onDrop = useCallback(files => {
    setLoading(true);
    var formData = new FormData();

    const [file] = files;

    formData.append("file", file);
    formData.append("filename", file.name);

    fetch(`${uploadUrl}/upload`, {
      method: "POST",
      body: formData
    })
      .then(response => response.json())
      .then(responseJson => {
        context.onProductAdd({
          sku: restProps.sku,
          image: `data:image/png;base64, ${responseJson.file}`,
          original: file,
          group: restProps.group,
          price: restProps.price,
          type: restProps.type
        });

        setLoading(false);

        return onLoad(responseJson.file);
      })
      .catch(error => {
        setLoading(false);
        alert(error);
      });
  }, []);

  const baseStyles = StyleSheet.create({
    rejected: {
      border: "1px solid red",
      padding: spacing.space1
    }
  });

  const { rejectedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxSize: "5000000",
    accept: "image/jpeg, image/png"
  });

  const rejectedFilesItems = rejectedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <div className={`${className} form-group`}>
      {userImg ? (
        <React.Fragment>
          <POptionImage
            active
            onRemove={() => {
              onLoad(null);
            }}
            image={userImg.src}
          />
          <Copy size="tiny">
            You can move, rotate and resize your image to fit to the ring, on
            the left.
          </Copy>
        </React.Fragment>
      ) : (
        <div {...getRootProps()}>
          <label>{label}</label>
          <input disabled={loading} {...getInputProps()} />
          <div className="optionUpload">
            <PDescription>
              {loading ? (
                <Loading minHeight={false} />
              ) : (
                "Drag 'n' drop your file here, or click to select a file"
              )}
            </PDescription>
          </div>
          <aside>
            {rejectedFiles.length ? (
              <div className={css(baseStyles.rejected)}>
                <PDescription>Rejected files:</PDescription>
              </div>
            ) : null}
            {rejectedFiles.length ? (
              <Copy size="tiny">
                <ul>{rejectedFilesItems}</ul>
              </Copy>
            ) : null}
          </aside>
          <Copy size="tiny">
            File type: png or jpg
            <br />
            File size: max 5MB
          </Copy>
        </div>
      )}
    </div>
  );
};

OptionUpload.defaultProps = {
  active: false,
  title: null,
  image: null,
  uploaded: false,
  children: "Drop your file here or click to upload"
};

OptionUpload.propTypes = {
  /** Is the option active? */
  active: PropTypes.bool,
  /** Title of the Option */
  title: PropTypes.string,
  /** Descriptive text */
  children: PropTypes.string
};

OptionUpload.contextTypes = {
  onProductAdd: PropTypes.func
};

export default OptionUpload;
