import "babel-polyfill";
import "react-app-polyfill/ie11";
import React from "react";
import { render } from "react-dom";
import { ApolloClient } from "apollo-client";
import { ApolloProvider } from "react-apollo";
import { InMemoryCache} from "apollo-cache-inmemory";
import { createUploadLink } from "apollo-upload-client";
import * as Sentry from "@sentry/browser";
import { setContext } from "apollo-link-context";

import "./App.css";
import App from "./App";
import { CartContext, HomeImageContext } from "./components";

Sentry.init({
  dsn: "https://a50e6c6fa9ed44a290e23af22bea3d3d@sentry.io/1855970"
});

// const cache = new InMemoryCache({
//   dataIdFromObject: object => {
//     switch (object.__typename) {
//       case "ConfigurationInput":
//         return object.configId; // use `key` as the primary key
//       case "ConfigurationProduct":
//         return object.configId; // use `key` as the primary key
//       default:
//         return defaultDataIdFromObject(object); // fall back to default handling
//     }
//   }
// });

const link = createUploadLink({
  uri: process.env.REACT_APP_API_URL || "/graphql",
  credentials: "same-origin"
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      siteid: process.env.REACT_APP_SITE_ID
    }
  };
});

// Pass your GraphQL endpoint to uri
const client = new ApolloClient({
  link: authLink.concat(link),
  cache: new InMemoryCache({}),
  connectToDevTools: true
});

const ApolloApp = () => (
  <ApolloProvider client={client}>
    <CartContext>
      <HomeImageContext>
        <App />
      </HomeImageContext>
    </CartContext>
  </ApolloProvider>
);

render(<ApolloApp />, document.getElementById("root"));
