import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Media from "react-media";
import { Link } from "react-router-dom";
import {
  Section,
  SliderWrapper,
  Border,
  Button,
  ButtonText,
  PFooter,
  Card,
  Loading,
  Heading,
  Copy,
  POptionsGrid,
  POptionImage,
  PDescription
} from "@lucio-erasmus/tfgj-components";
import { spring, presets } from "react-motion";
import ReactImageMagnify from "react-image-magnify";
import { ComponentFinder, ImageBuilder, ProductTotal } from "../../components";
import { siteConfig } from "../../helpers/config";
import WishlistContainer from "./WishlistContainer";
import Helmet from "react-helmet";
import { formatPrice } from "../../helpers/utils";

class ProductContainer extends Component {
  state = {
    stack: [
      {
        key: "personalise"
      }
    ],
    prev: [],
    component: null,
    view: "view1",
    userImg: null
  };

  static contextTypes = {
    getErrors: PropTypes.func.isRequired,
    getProduct: PropTypes.func.isRequired,
    resetProduct: PropTypes.func.isRequired,
    loadProduct: PropTypes.func.isRequired,
    getTotal: PropTypes.func.isRequired,
    addToCart: PropTypes.func.isRequired,
    cart: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired
  };

  componentWillUnmount() {
    this.context.resetProduct();
  }

  componentDidMount() {
    if (this.props.match.params.cartId) {
      this.context.loadProduct(this.props.match.params.cartId);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.cartId !== prevProps.match.params.cartId) {
      this.context.loadProduct(this.props.match.params.cartId);
    }
  }
  componentWillReceiveProps(nextProps) {
    if (
      this.props.match.params.productId !== nextProps.match.params.productId
    ) {
      this.context.resetProduct();
      this.context.loadProduct(this.props.match.params.cartId || null);
      this.setState({
        stack: [
          {
            key: "personalise"
          }
        ],
        prev: [],
        component: null,
        userImg: null,
        view: "view1"
      });
    }
  }

  insert = c => {
    const newItem = {
      data: {
        ...c
      },
      key: c.type
    };

    const prev = this.state.stack;

    this.setState({ stack: [newItem], prev });
  };

  remove = item => {
    const stack = this.state.stack.filter(c => c.key !== item);
    if (stack.length) {
      this.setState({ stack });
    } else {
      this.setState({ stack: [{ key: "personalise" }] });
    }
  };

  getDefaultStyles = () => {
    const styles = this.state.stack.map(c => ({
      ...c,
      style: { translateX: 220 }
    }));
    return styles;
  };

  getStyles = () => {
    const { stack } = this.state;

    return stack.map((todo, i) => {
      return {
        ...todo,
        style: {
          translateX: spring(0, presets.noWobble)
        }
      };
    });
  };

  toCSSTranslate = translateX => ({ transform: `translateX: ${translateX}px` });

  willEnter = () => {
    return {
      ...this.toCSSTranslate(0)
    };
  };

  willLeave = () => {
    return {
      ...this.toCSSTranslate(0)
    };
  };

  addToCart = () => {
    const {
      product: p,
      match: {
        params: { cartId = null }
      }
    } = this.props;

    this.context
      .addToCart(p, cartId)
      .then(data => {
        this.props.history.push("/cart");
      })
      .catch(err => {
        console.log(err);
      });
  };

  getProductRange = categories => {
    if (categories.length) {
      const { ranges } = this.props;
      const cat = categories[0].id;
      const connected = ranges.find(r => r.connected_category === cat);
      if (connected) {
        return (
          <Link
            to={`/range/${connected.id}-${connected.slug}`}
            key={connected.id}
          >
            <ButtonText size="medium" text iconBefore="arrow-left" center>
              Back to {connected.title}
            </ButtonText>
          </Link>
        );
      }
    }

    return null;
  };

  addToCanvas = file => {
    let image = null;
    if (file) {
      image = {
        type: "userImage",
        src: `data:image/png;base64, ${file}`,
        scaleHeight: 300,
        centerObject: true,
        options: {
          originX: "left",
          originY: "top",
          hasControls: true
        }
      };
    }

    this.setState({
      userImg: image
    });
  };
  relatedCard = (card, index) => {
    return (
      <div key={index} className="col-lg-2 col-md-3 col-4">
        <Link to={`/product/${card.id}-${card.slug}`} key={card.id}>
          <Card
            vertical
            portrait
            title={card.name}
            description={
              card.on_sale
                ? `<span class="sale">${
                    card.variations ? "Was from" : "Was"
                  } R${formatPrice(card.priceText)}</span><br/><strong>${
                    card.variations ? "Now from" : "Now"
                  } R${formatPrice(card.salesPriceText)}</strong>`
                : card.from_price
                ? `${card.variations ? "From" : ""} R${formatPrice(
                    card.from_price
                  )}`
                : `${card.variations ? "From" : ""} R${formatPrice(
                    card.priceText
                  )}`
            }
            image={card.featuredImage}
            eyeCatcher={card.eyeCatcher ? card.eyeCatcher.value : null}
          />
        </Link>
      </div>
    );
  };

  render() {
    if (this.props.loading) return <Loading />;

    const configuration = this.context.getProduct();
    const {
      product: p,
      match: {
        params: { cartId = null }
      }
    } = this.props;

    const selectionErrors = Object.keys(this.context.getErrors()).length;

    return [
      <Helmet
        defaultTitle={`${p.name} - American Swiss`}
        meta={[
          { name: "description", content: p.short_description },
          { name: "og:title", content: `${p.name} - American Swiss` },
          { name: "og:description", content: p.short_description },
          {
            name: "og:url",
            content: `https://personalise.americanswiss.co.za/product/${p.slug}`
          },
          { name: "og:site_name", content: `American Swiss` },
          { name: "og:type", content: `website` },
          {
            name: "og:image",
            content: p.featuredImage.hasImage ? p.featuredImage.url : ""
          }
        ]}
        htmlAttributes={{ lang: "en" }}
      />,
      <Section mt="small" rowClass="" mb="medium">
        <div className="row">
          <div className="col-lg-4">
            <Media
              query="(min-width: 576px)"
              render={() => <Border color="white" />}
            />

            <Media
              query="(min-width: 576px)"
              render={() => (
                <div style={{ textAlign: "center" }}>
                  {this.getProductRange(p.categories)}
                </div>
              )}
            />

            <Border color="white" mt="small" mb="none" />
            <Heading center mb="small" size="large" weight="bold">
              {p.name}
            </Heading>

            <Copy center sans html={p.short_description} />

            <Copy center size="small">
              {p.specifications.value}
            </Copy>

            <Media
              query="(min-width: 576px)"
              render={() => (
                <Heading center sans weight="bold">
                  <ProductTotal
                    productPrice={p.priceText}
                    productId={p.id}
                    configuration={configuration}
                  />
                </Heading>
              )}
            />
          </div>

          <div className="col-sm-6 col-lg-4">
            {p.configuration && p.configuration.id ? (
              <ImageBuilder
                productId={p.configuration.id}
                masks={p.configuration.statics}
                view={this.state.view || "view1"}
                userImg={this.state.userImg}
                render1={url => (
                  <Fragment>
                    <ReactImageMagnify
                      {...{
                        enlargedImagePosition: "over",
                        smallImage: {
                          isFluidWidth: true,
                          src: `${url}&dimW=325&dimH=325`,
                          srcSet: `${url}&dimW=325&dimH=325 325w, ${url}&dimW=650&dimH=650 650w, ${url}&dimW=975&dimH=975 975w`,
                          sizes: "325px"
                        },
                        largeImage: {
                          width: 750,
                          height: 1125,
                          src: url
                        },
                        enlargedImageStyle: {
                          background: "#fff"
                        }
                      }}
                    />
                    {p.configuration.views && p.configuration.views.length ? (
                      <POptionsGrid justify={"center"}>
                        {p.configuration.views.map((v, index) => (
                          <ImageBuilder
                            key={index}
                            productId={p.configuration.id}
                            view={v}
                            render={url => (
                              <div>
                                <POptionImage
                                  onClick={() => this.setState({ view: v })}
                                  active={this.state.view === v}
                                  image={`${url}&dimW=60&dimH=60`}
                                  title={this.state.view === v}
                                />
                              </div>
                            )}
                          />
                        ))}
                      </POptionsGrid>
                    ) : null}
                  </Fragment>
                )}
              />
            ) : (
              <Card vertical portrait image={p.featuredImage} />
            )}
          </div>
          <div className="col-sm-6 col-lg-4">
            <Border color="white" />
            <div
              style={{ position: "relative", height: "100%", overflow: "auto" }}
            >
              <ComponentFinder
                configuration={
                  p.configuration ? p.configuration.components : []
                }
                p={p}
                product={configuration}
                insert={this.insert}
                addToCanvas={this.addToCanvas}
                userImg={this.state.userImg}
              />

              <PFooter>
                <div className="d-flex justify-content-between">
                  <Heading left mb="small" color="light" sans size="medium">
                    Total
                  </Heading>
                  <Heading left mb="none" sans size="medium">
                    <ProductTotal
                      productPrice={p.priceText}
                      productId={p.id}
                      configuration={configuration}
                    />
                  </Heading>
                </div>
                <Button
                  full
                  size="medium"
                  disabled={this.context.loading}
                  onClick={this.addToCart}
                >
                  {this.context.loading ? (
                    <Loading minHeight={false} />
                  ) : cartId ? (
                    siteConfig.updateCart
                  ) : (
                    siteConfig.addToCart
                  )}
                </Button>
                {selectionErrors ? (
                  <React.Fragment>
                    <Border color="white" mb="none" mt="none" />
                    <Copy size="small" color="error" mb="none">
                      You have not selected all the required options.
                    </Copy>
                  </React.Fragment>
                ) : null}
              </PFooter>
              <PDescription html={p.shipping_info.value} />
              {/* <Copy size="small">{p.shipping_info.value}</Copy> */}
              <WishlistContainer product={p} configuration={configuration} />
              <WishlistContainer
                icon={"hint"}
                text={siteConfig.hintText}
                modalTitle={siteConfig.hintHeadline}
                modalCopy={siteConfig.hintCopy}
                modalSuccess={siteConfig.hintSuccess}
                template="hint"
                product={p}
                configuration={configuration}
              />
            </div>
          </div>
        </div>
      </Section>,
      p.related.length ? (
        <Section mt="medium" mb="none" container="container-fluid">
          <div className="col-md-12 featured-cards-wrapper">
            <Heading
              uppercase
              sans
              center
              size="medium"
              mb="large"
              weight="bold"
            >
              Other Products You Might Like
            </Heading>

            <Media
              query="(min-width: 1200px)"
              render={() => (
                <div className="row justify-content-center">
                  {p.related.map((r, index) => this.relatedCard(r, index))}
                </div>
              )}
            />

            <Media
              query="(min-width: 994px) and (max-width: 1199px)"
              render={() => (
                <Fragment>
                  {p.related.length > 4 ? (
                    <SliderWrapper>
                      {p.related.map((r, index) => this.relatedCard(r, index))}
                    </SliderWrapper>
                  ) : (
                    <div className="row justify-content-center">
                      {p.related.map((r, index) => this.relatedCard(r, index))}
                    </div>
                  )}
                </Fragment>
              )}
            />

            <Media
              query="(min-width: 768px) and (max-width: 993px)"
              render={() => (
                <Fragment>
                  {p.related.length > 3 ? (
                    <SliderWrapper>
                      {p.related.map((r, index) => this.relatedCard(r, index))}
                    </SliderWrapper>
                  ) : (
                    <div className="row justify-content-center">
                      {p.related.map((r, index) => this.relatedCard(r, index))}
                    </div>
                  )}
                </Fragment>
              )}
            />

            <Media
              query="(min-width: 576px) and (max-width: 767px)"
              render={() => (
                <Fragment>
                  {p.related.length > 2 ? (
                    <SliderWrapper>
                      {p.related.map((r, index) => this.relatedCard(r, index))}
                    </SliderWrapper>
                  ) : (
                    <div className="row justify-content-center">
                      {p.related.map((r, index) => this.relatedCard(r, index))}
                    </div>
                  )}
                </Fragment>
              )}
            />

            <Media
              query="(max-width: 575px)"
              render={() => (
                <Fragment>
                  {p.related.length > 1 ? (
                    <SliderWrapper>
                      {p.related.map((r, index) => this.relatedCard(r, index))}
                    </SliderWrapper>
                  ) : (
                    <div className="row justify-content-center">
                      {p.related.map((r, index) => this.relatedCard(r, index))}
                    </div>
                  )}
                </Fragment>
              )}
            />
          </div>
        </Section>
      ) : null
    ];
  }
}

export default ProductContainer;
