import React, { Fragment } from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";
import { graphql, compose } from "react-apollo";
import { gql } from "apollo-boost";
import { omit } from "ramda";
import { ButtonText, Billboard, Button } from "@lucio-erasmus/tfgj-components";
import { siteConfig } from "../../helpers/config";
import { dataURItoBlob } from "../../helpers/utils";
import FormContainer from "../Form/FormContainer";
import { screenshot } from "./v3d/config";

const customStyles = {
  overlay: {
    zIndex: 2000
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 0,
    border: 0,
    borderRadius: 0,
    minWidth: "200px",
    maxWidth: "95%",
    maxHeight: "80%", // <-- This sets the height
    overlfow: "scroll"
  }
};

class BookContainer extends React.Component {
  constructor() {
    super();

    this.state = {
      modalIsOpen: false,
      loading: false,
      error: null
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  static contextTypes = {
    getProduct: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired
  };

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false, sent: false });
  }

  submitForm = async (errors, values) => {
    this.setState({
      loading: true
    });

    const {
      configuration,
      product: { id: productId }
    } = this.props;

    const files = [];

    const capture = await dataURItoBlob(await screenshot());
    if (capture) {
      capture.name = "canvass.png";
      files.push(capture);
    }

    return this.props
      .saveProduct({
        variables: {
          input: {
            productId,
            ...values,
            template: "book",
            files,
            imagePosition: null,
            configuration: [
              ...configuration.map(p =>
                omit(
                  [
                    "__typename",
                    "configId",
                    "styles",
                    "image",
                    "original",
                    "exclusions",
                    "specs"
                  ],
                  p
                )
              )
            ]
          }
        }
      })
      .then(() => {
        this.setState({
          sent: true,
          loading: false
        });
      })
      .catch(err => {
        this.setState({
          loading: false,
          error: err
        });
      });
  };

  render() {
    return (
      <Fragment>
        <div className="mt-2">
          <ButtonText
            onClick={this.openModal}
            size="medium"
            text
            iconBefore={this.props.icon}
          >
            {this.props.text}
          </ButtonText>
        </div>
        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
        >
          <Billboard
            small={true}
            border={true}
            title={this.props.modalTitle}
            description={
              !this.state.sent ? this.props.modalCopy : this.props.modalSuccess
            }
          >
            {!this.state.sent ? (
              <div className="book-form">
                <FormContainer
                  formId={"appointment"}
                  submitProcess={this.submitForm}
                  renderSubmit={() => (
                    <div className="col-md-12">
                      <Button full iconAfter="arrow-right" size="large">
                        {this.state.loading ? "Loading" : "Submit"}
                      </Button>
                    </div>
                  )}
                />
              </div>
            ) : null}
          </Billboard>
        </Modal>
      </Fragment>
    );
  }
}

BookContainer.defaultProps = {
  template: "asjWishlist",
  icon: "calendar",
  text: siteConfig.appointmentTitle,
  modalTitle: siteConfig.appointmentTitle,
  modalCopy: siteConfig.appointmentText,
  modalSuccess: siteConfig.saveSuccess
};

const SAVE_PRODUCT_MUTATION = gql`
  mutation saveProduct($input: SaveProductInput!) {
    saveProduct(input: $input)
  }
`;
const withSaveProduct = graphql(SAVE_PRODUCT_MUTATION, { name: "saveProduct" });

export default compose(withSaveProduct)(BookContainer);
