import React from "react";
import { Copy } from "@lucio-erasmus/tfgj-components";

const TextField = ({
  id,
  className,
  error,
  label,
  type,
  placeholder,
  description,
  ...input
}) => {
  return (
    <div className={`${className} form-group`}>
      <label>{label}</label>
      <input
        name={id}
        placeholder={placeholder}
        type={type}
        className="form-control"
        {...input}
      />
      {error && (
        <Copy mb="0" color="error" size="small">
          {error}
        </Copy>
      )}
      {!error && description ? (
        <Copy mb="0" size="tiny">
          {description}
        </Copy>
      ) : null}
    </div>
  );
};

TextField.defaultProps = {
  className: "col-md-6 form-group"
};

export default TextField;
