import React from "react";
import { graphql, compose } from "react-apollo";
import { gql } from "apollo-boost";
import serializeForm from "form-serialize";
import { Button, Heading } from "@lucio-erasmus/tfgj-components";
import { siteConfig } from "../../helpers/config";

class NewsletterContainer extends React.Component {
  state = {
    sent: false
  };

  componentWillUnmount() {
    this.setState({
      sent: false
    });
  }

  onSubmit = e => {
    e.preventDefault();

    this.setState({
      sent: false,
      loading: true
    });

    const values = serializeForm(e.target, { hash: true });

    this.props
      .createSubscription({
        variables: {
          input: {
            form: "newsletter",
            ...values
          }
        }
      })
      .then(({ data }) => {
        this.setState(
          {
            sent: true,
            loading: false
          },
          () => this.form.reset()
        );
      });
  };
  render() {
    const { sent } = this.state;

    return (
      <div
        style={{ background: "#000" }}
        className="py-4 news-letter-wrapper"
      >
        <div className="container news-letter-container">
          {sent ? (
            <Heading size="small" color="white">
              {siteConfig.newsletterConfirmation}
            </Heading>
          ) : (
            <form
              ref={node => (this.form = node)}
              className="dark"
              onSubmit={this.onSubmit}
            >
              <div className="row align-items-center">
                <div className="col-md-3 news-letter-header">
                  <Heading size="small" color="white">
                    {siteConfig.newsletterHeadline}
                  </Heading>
                </div>
                <div className="col-md-7">
                  <div className="form-group">
                    <input
                      className="form-control form-control-lg"
                      name="email"
                      type="email"
                      placeholder="Your Email Address"
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <Button full size="small" color="white">
                      Subscribe
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    );
  }
}

const CREATE_SUBSCRIPTION = gql`
  mutation createSubscription($input: SubscriptionInput!) {
    createSubscription(input: $input)
  }
`;

const withCreateSubscription = graphql(CREATE_SUBSCRIPTION, {
  name: "createSubscription"
});

export default compose(withCreateSubscription)(NewsletterContainer);
