import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress
} from "react-places-autocomplete";
import {
  iterateAdreessAndRetrieve,
  formatAddressObject,
  formatLocationData
} from "../../helpers/utils";
import { Copy } from "@lucio-erasmus/tfgj-components";

class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: "" };
  }

  handleChange = address => {
    this.setState({ address });
  };

  handleSelect = address => {
    geocodeByAddress(address)
      .then(results => {
        if (results && results.length) {
          return results[0];
        }

        return [];
      })
      .then(enteredLocation => {
        this.setState({
          loading: false
        });

        const addressObject = iterateAdreessAndRetrieve(enteredLocation);
        const formattedAddressObject = formatAddressObject(addressObject);
        const formattedAddressStringWithCountry =
          enteredLocation.formatted_address;
        const formattedAddressStringWithoutCountry = formattedAddressStringWithCountry.replace(
          /,[^,]+$/,
          ""
        );
        const formattedAddressString = formattedAddressStringWithCountry;
        const placeId = enteredLocation.place_id;
        const lat = enteredLocation.geometry.location.lat();
        const lng = enteredLocation.geometry.location.lng();
        const geoLocation = { lat, lng };

        const formattedLocationData = formatLocationData(
          placeId,
          formattedAddressObject,
          formattedAddressString,
          formattedAddressStringWithoutCountry,
          geoLocation
        );

        if (this.props.onSelect) {
          this.props.onSelect(formattedLocationData);
        }

        this.handleChange(formattedAddressString);
      })
      .catch(error => {
        console.log("Geocode Error", error);
      });
  };

  render() {
    return (
      <div className={`${this.props.field.className} form-group`}>
        <label>{this.props.field.label}</label>
        <PlacesAutocomplete
          value={this.state.address}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
          searchOptions={{ componentRestrictions: { country: "za" } }}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading
          }) => (
            <div className="Demo__search-bar-container">
              <div className="Demo__search-input-container">
                <input
                  {...getInputProps({
                    placeholder: "Start typing your street name...",
                    className: "form-control"
                  })}
                />
              </div>
              {suggestions.length > 0 && (
                <div className="Demo__autocomplete-container">
                  {suggestions.map(suggestion => {
                    const className = `Demo__suggestion-item ${
                      suggestion.active ? "Demo__suggestion-item--active" : ""
                    }`;

                    return (
                      /* eslint-disable react/jsx-key */
                      <div
                        {...getSuggestionItemProps(suggestion, { className })}
                      >
                        <strong>
                          {suggestion.formattedSuggestion.mainText}
                        </strong>{" "}
                        <small>
                          {suggestion.formattedSuggestion.secondaryText}
                        </small>
                      </div>
                    );
                    /* eslint-enable react/jsx-key */
                  })}
                </div>
              )}
            </div>
          )}
        </PlacesAutocomplete>
        {this.props.error && (
          <Copy mb="0" color="red" size="small">
            {this.props.error}
          </Copy>
        )}
        {!this.props.error && this.props.description ? (
          <Copy mb="0" size="tiny">
            {this.props.description}
          </Copy>
        ) : null}
      </div>
    );
  }
}

export default LocationSearchInput;
