const site_id = process.env.REACT_APP_SITE_ID || "ASJ";

const config = {
  ASJ: {
    appointmentTitle: "Book an Appointment",
    appointmentText:
      "If you need assistance with your design or would like to book a consultation with our design team fill in the form below and we will contact you directly to arrange a suitable date and time.",
    bespoke:
      "If you haven't found exactly what you're looking for, our team of experts can help you bring your dream ring to life. <a class='link-style' target='_blank' rel='noopenner norefferer' href='/design-your-own-ring/bespoke-design-studio/'>Find out more.</a>",
    threeD:
      "<strong>Disclaimer:</strong> 3D model is a Graphic technical Representation",
    site: "ASJ",
    addToCart: "Add to bag",
    updateCart: "Update Product",
    finePrint:
      "View the terms & conditions for the delivery times and other details relating to your selected items.",
    viewMore: "Explore",
    saveText: "Save",
    saveHeadline: "Want another look?",
    saveCopy:
      "Enter your email address to receive a link to view your saved product.",
    saveSuccess: "Your link has been sent to your email.",
    hintText: "Hint",
    hintHeadline: "Hint, hint!",
    hintCopy: "Like what you see? Give someone special a gentle nudge.",
    hintSuccess: "Your hint has been sent to the recipient.",
    backToLink: "http://www.americanswiss.co.za",
    backToText: "Back to American Swiss website",
    newsletterHeadline: "SIGN UP TO OUR NEWSLETTER",
    newsletterCopy: null,
    newsletterConfirmation: "Thank you for subscribing",
    bespokeConfirmation:
      "Thank you for your submission. We will get back to you as soon as possible",
    bespokeError: "Something went wrong. Please try again later.",
    footerAttribution:
      'Created by <a target="_blank" href="http://www.plusplusminus.co.za/?utm_source=ASJ&utm_medium=Footer&utm_campaign=Personalisation">PlusPlusMinus</a>',
    footerLegalText:
      "Copyright 2014 American Swiss Ltd. All Rights Reserved. E&OE. For further information please call (0)21 938 1943",
    terms:
      "https://www.americanswiss.co.za/browse/staticContent.jsp?pageName=termsAndConditions",
    footerLinks: [
      {
        label: "Information Act",
        url: "http://www.tfg.co.za/access-to-information"
      },
      {
        label: "Disclaimer",
        url: "http://www.tfg.co.za/privacy-statement"
      },
      {
        label: "Terms & Conditions",
        url:
          "http://americanswiss.co.za/userdocs/TFG%20website%20terms%20and%20conditions.pdf"
      },
      {
        label: "Suppliers",
        url: "http://www.suppliers-tfglimited.co.za/"
      },
      {
        label: "Contact Us",
        url: "http://americanswiss.co.za/contact/enquiries/"
      }
    ],
    footerLinksSocial: [
      {
        iconName: "facebook",
        label: "Facebook",
        url: "http://www.facebook.com/AmericanSwiss"
      },
      {
        iconName: "twitter",
        label: "Twitter",
        url: "https://twitter.com/AmericanSwiss"
      },
      {
        iconName: "instagram",
        label: "Instagram",
        url: "http://instagram.com/americanswissza/"
      },
      {
        iconName: "youtube",
        label: "YouTube",
        url: "http://www.youtube.com/americanswissza"
      }
    ]
  }
};

export const siteConfig = {
  ...config.asj,
  ...config[site_id]
};
