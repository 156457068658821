import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Nav.css";
import { MobileMenu } from "../../components";

const Nav = ({ menu }) => {
  const { menuItems } = menu;
  return (
    <div style={{ display: "relative" }}>
      <nav className="bottom-nav-container">
        <div>
          <ul className="bottom-nav-nav-links">
            {menuItems.edges.map(({ node }) => {
              return (
                <li key={node.key} className={`${node.cssClasses.map(c => c)}`}>
                  {(node.connectedNode &&
                  node.connectedNode.node.pageOptions &&
                  node.connectedNode.node.pageOptions.linksToBash) || (node.target === '_blank') ? (
                    <a
                      href={
                        node.connectedNode &&
                        node.connectedNode.node.pageOptions && node.connectedNode.node.pageOptions.bashcomDestination ?
                        node.connectedNode.node.pageOptions.bashcomDestination: node.uri
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {node.label}
                    </a>
                  ) : (
                    <NavItem
                      label={node.label}
                      uri={node.uri}
                      childItems={node.childItems.edges}
                      className={`${node.cssClasses.map(c => c)}`}
                    />
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </nav>
      <MobileMenu menu={menu} />
    </div>
  );
};

const NavItem = ({ label, childItems, uri }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const handleMouseEnter = () => {
    setShowDropdown(true);
  };

  const handleMouseLeave = () => {
    setShowDropdown(false);
  };

  return (
    <a
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      href={uri}
    >
      {label}
      {showDropdown && childItems && childItems.length > 0 && (
        <div className="dropdown">
          <ul>
            {childItems &&
              childItems.map(({ node }) => {
                return (
                  <li
                    key={node.key}
                    className={`${node.cssClasses.map(c => c)}`}
                  >
                    {(node.connectedNode &&
                      node.connectedNode.node.pageOptions &&
                      node.connectedNode.node.pageOptions.linksToBash) ||
                    node.connectedNode === null ? (
                      <a
                        href={
                          node.connectedNode &&
                          node.connectedNode.node.pageOptions &&
                          node.connectedNode.node.pageOptions.bashcomDestination
                            ? node.connectedNode.node.pageOptions
                                .bashcomDestination
                            : node.uri
                        }
                        target={node.target}
                        rel={node.target === "_blank" && "noopener noreferrer"}
                      >
                        {node.label}
                      </a>
                    ) : (
                      <Link
                        className={`${node.cssClasses.map(c => c)}`}
                        to={`${
                          node &&
                          node.connectedNode &&
                          node.connectedNode.node.uri
                            ? node.connectedNode.node.uri
                            : node.uri
                        }`}
                      >
                        {node.label}
                      </Link>
                    )}
                  </li>
                );
              })}
          </ul>
        </div>
      )}
    </a>
  );
};

export default Nav;
