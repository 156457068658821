import { GraphQLClient, gql } from "graphql-request";

/*const flatListToHierarchical = (
  data = [],
  { idKey = "key", parentKey = "parentId", childrenKey = "children" } = {}
) => {
  const tree = [];
  const childrenOf = {};
  data.forEach(item => {
    const newItem = { ...item };
    const { [idKey]: id, [parentKey]: parentId = 0 } = newItem;
    childrenOf[id] = childrenOf[id] || [];
    newItem[childrenKey] = childrenOf[id];
    parentId
      ? (childrenOf[parentId] = childrenOf[parentId] || []).push(newItem)
      : tree.push(newItem);
  });
  return tree;
};*/

export async function getMenu(menuId) {
  const endpoint =
    process.env.REACT_APP_WP_GRAPHQL ||
    "https://tfgasjstaging.wpengine.com/graphql";

  const graphQLClient = new GraphQLClient(endpoint, {});

  const query = gql`
    {
      productCategories(
        first: 100
        where: {
          parent: 0
          exclude: [79, 21, 20, 25, 22, 24, 15, 16, 26, 27, 19, 18, 23]
        }
      ) {
        edges {
          node {
            key: id
            databaseId
            name
            slug
            uri
            children {
              edges {
                node {
                  key: id
                  databaseId
                  name
                  uri
                  children {
                    edges {
                      node {
                        key: id
                        databaseId
                        name
                        uri
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  const { productCategories } = await graphQLClient.request(query, {
    id: "dGVybToyNw=="
  });

  return productCategories;
}

export async function getRange(slug) {
  const endpoint =
    process.env.REACT_APP_WP_GRAPHQL ||
    "https://tfgasjstaging.wpengine.com/graphql";

  const graphQLClient = new GraphQLClient(endpoint, {});

  const query = gql`
    query getRange($slug: ID!) {
      productCategory(id: $slug, idType: SLUG) {
        name
        seo {
          title
          metaDesc
          fullHead
        }
        DYORCategory {
          description
          headline
          content
          color
          bannerImage {
            mediaItemUrl
          }
          bannerImageMobile {
            mediaItemUrl
          }
          headlineimage {
            mediaItemUrl
          }
        }
        parent {
          node {
            name
            parent {
              node {
                name
              }
            }
          }
        }
        products(first: 100) {
          edges {
            node {
              id: productId
              slug
              image {
                sourceUrl
              }
              productOptions {
                eyecatcher
              }
              ... on SimpleProduct {
                name
                price
                salePrice
                onSale
                shortDescription
              }
            }
          }
        }
      }
    }
  `;

  const { productCategory } = await graphQLClient.request(query, {
    slug: slug
  });

  return productCategory;
}

export async function getHomeQuery(slug) {
  const endpoint =
    process.env.REACT_APP_WP_GRAPHQL ||
    "https://tfgasjstaging.wpengine.com/graphql";

  const graphQLClient = new GraphQLClient(endpoint, {});

  const query = gql`
    query HomePageQuery {
      page(idType: URI, id: "home") {
        title
        ranges {
          ranges {
            ... on Productrange {
              id
              headlineOptions {
                headline
                description
              }
              rangeOptions {
                linkUri
                eyecatcher
              }
              heroImageOptions {
                heroImage {
                  sourceUrl
                }
                hotspot: imageHotspot
              }
            }
          }
        }
      }
    }
  `;

  const { page } = await graphQLClient.request(query);

  return page;
}

export async function getAnouncements() {
  const endpoint =
    process.env.REACT_APP_WP_GRAPHQL ||
    "https://tfgasjstaging.wpengine.com/graphql";

  const graphQLClient = new GraphQLClient(endpoint, {});

  const query = gql`
    query Announcements {
      announcements {
        edges {
          node {
            announcementId
            announcementOptions {
              headline
              content
              isactive
              hasschedule
              publishDate
              unpublishDate
            }
          }
        }
      }
    }
  `;

  const { announcements } = await graphQLClient.request(query);

  return announcements;
}

export async function getDYORPage(slug) {
  const endpoint =
    process.env.REACT_APP_WP_GRAPHQL ||
    "https://tfgasjstaging.wpengine.com/graphql";

  const graphQLClient = new GraphQLClient(endpoint, {});

  const query = gql`
    query getDYORPage {
      page(idType: URI, id: "design-your-own-ring") {
        title
        content
        id
        seo {
          metaDesc
          title
          fullHead
        }
        image: featuredImage {
          node {
            mediaItemUrl
          }
        }
        DYOR {
          bannerImage {
            mediaItemUrl
          }
          bannerImageMobile {
            mediaItemUrl
          }
          headlineImage {
            mediaItemUrl
          }
          imageMenu {
            itemName
            itemLink
            itemImage {
              id
              mediaItemUrl
            }
            itemImageMobile {
              id
              mediaItemUrl
            }
          }
        }
      }
    }
  `;

  const { page } = await graphQLClient.request(query);

  return page;
}

export async function getBespokePage(slug) {
  const endpoint =
    process.env.REACT_APP_WP_GRAPHQL ||
    "https://tfgasjstaging.wpengine.com/graphql";

  const graphQLClient = new GraphQLClient(endpoint, {});

  const query = gql`
    query getBespokePage {
      page(idType: URI, id: "design-your-own-ring/bespoke-design-studio") {
        title
        content
        id
        seo {
          metaDesc
          title
          fullHead
        }
        headlineOptions {
          description
          headline
          color
          hideText
        }
        heroImageOptions {
          heroImage {
            url: sourceUrl
          }
          imageHotspot
        }

        image: featuredImage {
          node {
            mediaItemUrl
          }
        }
      }
    }
  `;

  const { page } = await graphQLClient.request(query);

  return page;
}
const endpoint =
  process.env.REACT_APP_WP_GRAPHQL ||
  "https://tfgasjstaging.wpengine.com/graphql";
export async function getPage(slug) {
  const graphQLClient = new GraphQLClient(endpoint, {});

  const query = gql`
    query getPage($slug: ID!) {
      page(idType: URI, id: $slug) {
        id
        seo {
          metaDesc
          title
          fullHead
        }
        parentDatabaseId
        slug
        title(format: RENDERED)
        pageOptions {
          isFullWidth
          eyecatcher
          excerpt
          publitas
          publitasUrl
          personalisedChildPageMenu {
            __typename
            name
            uri
            description
            image {
              sourceUrl
            }
            DYORCategory {
              eyecatcher
            }
          }
          childPageMenu {
            __typename
            ... on Page {
              id
              headlineOptions {
                headline
              }
              title(format: RENDERED)
              slug
              uri
              parentDatabaseId
              parentId
              pageOptions {
                linksToBash
                bashcomDestination
                excerpt
                eyecatcher
              }
              featuredImage {
                node {
                  sourceUrl
                }
              }
            }
            ... on Productrange {
              id
              uri
              title(format: RENDERED)
              excerpt(format: RENDERED)
              heroImageOptions {
                heroImage {
                  sourceUrl
                }
              }
              rangeOptions {
                eyecatcher
                linkUri
                connectedCategory {
                  uri
                }
              }
            }
          }
        }
        content(format: RENDERED)
        headlineOptions {
          description
          headline
          color
          hideText
        }
        heroImageOptions {
          heroImage {
            url: sourceUrl
          }
          imageHotspot
        }
      }
    }
  `;

  const { page } = await graphQLClient.request(query, { slug });

  return page;
}

export async function getMainMenu() {
  const endpoint =
    process.env.REACT_APP_WP_GRAPHQL ||
    "https://tfgjasjstg.wpengine.com/graphql";

  const graphQLClient = new GraphQLClient(endpoint, {});
  const query = gql`
    {
      menu(idType: NAME, id: "Main") {
        menuItems(first: 100, where: { parentDatabaseId: 0 }) {
          edges {
            node {
              id
              label
              uri
              url
              cssClasses
              target
              connectedNode {
                node {
                  __typename
                  ... on Page {
                    id
                    slug
                    uri
                    title(format: RENDERED)
                    pageOptions {
                      linksToBash
                      bashcomDestination
                    }
                  }
                }
              }
              childItems(first: 10) {
                edges {
                  node {
                    label
                    uri
                    url
                    cssClasses
                    target
                    connectedNode {
                      node {
                        __typename
                        ... on Page {
                          id
                          slug
                          title(format: RENDERED)
                          pageOptions {
                            linksToBash
                            bashcomDestination
                          }
                        }
                        ... on Productrange {
                          id
                          title(format: RENDERED)
                          slug
                          uri
                        }
                        ... on ProductCategory {
                          id
                          slug
                          name
                          uri
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  const { menu } = await graphQLClient.request(query);

  return menu;
}

export const setWpMenu = async () => {
  const endpoint =
    process.env.REACT_APP_WP_GRAPHQL ||
    "https://tfgjasjstg.wpengine.com/graphql";

  const graphQLClient = new GraphQLClient(endpoint, {});

  const query = gql`
    {
      menu(idType: NAME, id: "Footer") {
        menuItems(first: 100, where: { parentDatabaseId: 0 }) {
          edges {
            node {
              id
              label
              uri
              url
              connectedNode {
                node {
                  __typename
                }
              }
              childItems(first: 10) {
                edges {
                  node {
                    label
                    url
                    connectedNode {
                      node {
                        __typename
                        ... on Page {
                          id
                          slug
                          title(format: RENDERED)
                          pageOptions {
                            linksToBash
                            bashcomDestination
                          }
                        }
                        ... on Productrange {
                          id
                          title(format: RENDERED)
                          slug
                        }
                        ... on ProductCategory {
                          id
                          slug
                          name
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  const { menu } = await graphQLClient.request(query);
  return menu;
};
