import React, { useLayoutEffect, useState } from "react";
import { ButtonText, Copy } from "@lucio-erasmus/tfgj-components";
import { getAnouncements } from "../../helpers/wp";

const AnnouncementContainer = () => {
  const [a, setPage] = useState({
    loading: true,
    announcements: null
  });
  const [hidden, setHidden] = useState(false);
  const [activeAnnouncement, setActiveAnnouncement] = useState([]);

  const { announcements } = a;

   function filterActive(announcements) {
     const currentDate = new Date();
     const activeAnnouncement = announcements.edges.filter(({node}) => {
       if (
         node.announcementOptions.isactive &&
         (!node.announcementOptions.unpublishDate ||
           new Date(node.announcementOptions.unpublishDate) > currentDate) &&
         (!node.announcementOptions.publishDate ||
           new Date(node.announcementOptions.publishDate) <= currentDate)
       ) {
         return node;
       } else {return null}
     });

     setActiveAnnouncement(activeAnnouncement);
   }


  useLayoutEffect(() => {
    setPage({
      loading: true,
      announcements: null
    });
    getAnouncements().then(data => {
      filterActive(data);
      setPage({
        loading: false,
        announcements: data
      });
    });
  }, []);

  return (
    <div
      className="announcement"
      hidden={
        hidden ||
        !announcements ||
        announcements.edges.length === 0 ||
        activeAnnouncement.length === 0
      }
    >
      <div className="d-flex justify-content-end mr-2 mt-2">
        <ButtonText
          mb={"small"}
          target={"_blank"}
          iconBefore="close"
          color="gray"
          iconColor="white"
          onClick={() => setHidden(true)}
        >
          Close
        </ButtonText>
      </div>
      <div className="container m-auto">
        {activeAnnouncement && activeAnnouncement.length > 0
          ? activeAnnouncement.map((announcement, index) => {
              return (
                <AnnouncementItem
                  index={index}
                  key={index}
                  content={
                    announcement.node &&
                    announcement.node.announcementOptions &&
                    announcement.node.announcementOptions.content
                  }
                  headline={
                    announcement.node &&
                    announcement.node.announcementOptions &&
                    announcement.node.announcementOptions.headline
                  }
                  isActive={
                    announcement.node &&
                    announcement.node.announcementOptions &&
                    announcement.node.announcementOptions.isActive
                  }
                />
              );
            })
          : null}
      </div>
    </div>
  );
};

const AnnouncementItem = ({ content, headline, index }) => {
  return (
    <div>
      <div
        class={`d-flex justify-content-center flex-column align-items-center ${
          index > 0 ? "border-top white pt-4" : "border-0"
        }`}
      >
        
          <h6 style={{fontWeight: 600, lineHeigh: "0.2px"}}>{headline}</h6>
        <Copy size="large" color="gray" html={content} />
      </div>
    </div>
  );
};

export default AnnouncementContainer;
