import React, { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { HomeImageContext } from "../Store/HomeImageContext";

const Accordion = ({ menu, toggleSidebar }) => {
  const { menuItems } = menu;
  const history = useHistory();
  const { imageData } = useContext(HomeImageContext);
  const handleLinkClick = (event, uri) => {
    event.preventDefault();
    toggleSidebar();
    history.push(uri);
  };

  const [openItemId, setOpenItemId] = useState(null);

  const handleItemClick = itemId => {
    setOpenItemId(itemId === openItemId ? null : itemId);
  };

  const renderLevel = (items, parentId) => {
    return (
      items &&
      items.edges.map(({ node }, index) => {
        const itemId = parentId ? `${parentId}-${index}` : `${index}`;
        const childItems = node.childItems;
        const connectedNode = node.connectedNode;
        const isOpen = itemId === openItemId;

        return (
          <React.Fragment key={node.key}>
            <div className=" border-0 rounded-0 footer-menu-item-card">
              <div
                className={`card-header parent-header-card ${!childItems &&
                  "accordion-child-items"}`}
                id={`heading-${itemId}`}
              >
                <h5 className="accordion-header">
                  {childItems && childItems.edges.length > 0 ? (
                    <a
                      className={`collapsed ${isOpen ? "show" : ""}`}
                      role="button"
                      data-toggle="collapse"
                      href={`#collapse-${itemId}`}
                      aria-expanded={isOpen}
                      aria-controls={`collapse-${itemId}`}
                      onClick={() => handleItemClick(itemId)}
                    >
                      <span>{node.label}</span>
                      <span style={{ display: "block", fontSize: "22px" }}>
                        <i className="icon icon-angle-down"></i>
                      </span>
                    </a>
                  ) : (
                      connectedNode &&
                      connectedNode.node.pageOptions &&
                      connectedNode.node.pageOptions.linksToBash) ||
                      node.target === "_blank" ? (
                      <a
                        href={
                          connectedNode &&
                          connectedNode.node.pageOptions &&
                          connectedNode.node.pageOptions.bashcomDestination
                            ? connectedNode.node.pageOptions
                                .bashcomDestination
                            : node.uri
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {node.label}
                      </a>
                  ) : (
                    <Link
                      to={node.uri}
                      onClick={event => handleLinkClick(event, node.uri)}
                    >
                      {node.label}
                    </Link>
                  )}
                </h5>
              </div>

              {childItems ? (
                <div
                  id={`collapse-${itemId}`}
                  className={`collapse accordion-child-items ${
                    isOpen ? "show" : ""
                  }`}
                  data-parent="#accordion"
                  aria-labelledby={`heading-${itemId}`}
                >
                  <div className="card-body accordion-child-container">
                    {renderLevel(childItems, itemId)}
                  </div>
                </div>
              ) : null}
            </div>
          </React.Fragment>
        );
      })
    );
  };

  return (
    <React.Fragment>
      <div style={{ maxHeight: "600px", overflowY: "auto" }}>
        {imageData && (
          <div style={{ padding: "10px" }}>
            <div
              className="category-heading"
              style={{
                margin: "4px 1px",
                height: "200px",
                padding: "20px",
                backgroundImage: `url(${imageData})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover"
              }}
            ></div>
          </div>
        )}

        {renderLevel(menuItems)}
      </div>
    </React.Fragment>
  );
};

export default Accordion;
