import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Media from "react-media";
import { Link } from "react-router-dom";
import {
  Section,
  SliderWrapper,
  Button,
  ButtonText,
  PFooter,
  Card,
  Loading,
  Heading,
  Copy
} from "@lucio-erasmus/tfgj-components";
import { spring, presets } from "react-motion";
import { ComponentFinder, ProductTotal } from "../../components";
import { siteConfig } from "../../helpers/config";
import { formatPrice } from "../../helpers/utils";
import RingModel from "./ui/RingModel";
import WishlistContainer from "../Product/WishlistContainer";
import BookContainer from "./BookContainer";

class RingContainer extends Component {
  state = {
    stack: [
      {
        key: "personalise"
      }
    ],
    prev: [],
    component: null,
    view: "view1",
    ringMetal: "silver",
    ringName: "SIGNATURE10",
    isFromPrice: true
  };

  static contextTypes = {
    getErrors: PropTypes.func.isRequired,
    getProduct: PropTypes.func.isRequired,
    resetProduct: PropTypes.func.isRequired,
    loadProduct: PropTypes.func.isRequired,
    getTotal: PropTypes.func.isRequired,
    addToCart: PropTypes.func.isRequired,
    cart: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired
  };

  componentWillUnmount() {
    this.context.resetProduct();
  }

  componentDidMount() {
    if (this.props.match.params.cartId) {
      this.context.loadProduct(this.props.match.params.cartId);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.cartId !== prevProps.match.params.cartId) {
      this.context.loadProduct(this.props.match.params.cartId);
    }
  }
  componentWillReceiveProps(nextProps) {
    if (
      this.props.match.params.productId !== nextProps.match.params.productId
    ) {
      this.context.resetProduct();
      this.context.loadProduct(this.props.match.params.cartId || null);
      this.setState({
        stack: [
          {
            key: "personalise"
          }
        ],
        prev: [],
        component: null,
        userImg: null,
        view: "view1"
      });
    }
  }

  insert = c => {
    const newItem = {
      data: {
        ...c
      },
      key: c.type
    };

    const prev = this.state.stack;

    this.setState({ stack: [newItem], prev });
  };

  remove = item => {
    const stack = this.state.stack.filter(c => c.key !== item);
    if (stack.length) {
      this.setState({ stack });
    } else {
      this.setState({ stack: [{ key: "personalise" }] });
    }
  };

  getDefaultStyles = () => {
    const styles = this.state.stack.map(c => ({
      ...c,
      style: { translateX: 220 }
    }));
    return styles;
  };

  getStyles = () => {
    const { stack } = this.state;

    return stack.map((todo, i) => {
      return {
        ...todo,
        style: {
          translateX: spring(0, presets.noWobble)
        }
      };
    });
  };

  toCSSTranslate = translateX => ({ transform: `translateX: ${translateX}px` });

  willEnter = () => {
    return {
      ...this.toCSSTranslate(0)
    };
  };

  willLeave = () => {
    return {
      ...this.toCSSTranslate(0)
    };
  };

  addToCart = async () => {
    const {
      product: p,
      match: {
        params: { cartId = null }
      }
    } = this.props;

    this.context
      .addToCart(p, cartId)
      .then(() => {
        this.props.history.push("/cart");
      })
      .catch(err => {
        console.log(err);
      });
  };

  getProductRange = categories => {
    if (categories.length) {
      const { ranges } = this.props;
      const cat = categories[0].id;
      const connected = ranges.find(r => r.connected_category === cat);
      if (connected) {
        return (
          <Link
            to={`/range/${connected.id}-${connected.slug}`}
            key={connected.id}
          >
            <ButtonText size="medium" text iconBefore="arrow-left" center>
              Back to {connected.title}
            </ButtonText>
          </Link>
        );
      }
    }

    return null;
  };

  relatedCard = (card, index) => {
    return (
      <div key={index} className="col-lg-2 col-md-3 col-4">
        <Link to={`/product/${card.id}-${card.slug}`} key={card.id}>
          <Card
            vertical
            portrait
            title={card.name}
            description={
              card.on_sale
                ? `<span class="sale">${
                    card.variations ? "Was from" : "Was"
                  } R${formatPrice(card.priceText)}</span><br/><strong>${
                    card.variations ? "Now from" : "Now"
                  } R${formatPrice(card.salesPriceText)}</strong>`
                : card.from_price
                ? `${card.variations ? "From" : ""} R${formatPrice(
                    card.from_price
                  )}`
                : `${card.variations ? "From" : ""} R${formatPrice(
                    card.priceText
                  )}`
            }
            image={card.featuredImage}
            eyeCatcher={card.eyeCatcher ? card.eyeCatcher.value : null}
          />
        </Link>
      </div>
    );
  };

  setIsFrom = isFrom => {
    //this.setState({ isFromPrice: isFrom });
  };

  render() {
    if (this.props.loading) return <Loading />;
    const { ringMetal } = this.state;
    const configuration = this.context.getProduct();

    const {
      product: p,
      match: {
        params: { cartId = null }
      }
    } = this.props;

    return [
      <Section mt="small" rowClass="" mb="medium">
        <div className="row">
          <div className="col-sm-6 col-lg-8">
            <RingModel
              configuration={configuration}
              metal={ringMetal}
              ringName={p.configuration.name}
              ringStyle={p.configuration.style}
            />
          </div>
          <div className="col-sm-6 col-lg-4">
            <div
              style={{
                position: "sticky",
                top: "200px"
              }}
            >
              <ComponentFinder
                configuration={
                  p.configuration ? p.configuration.components : []
                }
                p={p}
                insert={this.insert}
              />
              <PFooter>
                <div className="d-flex justify-content-between mb-2">
                  <Heading left mb="small" color="light" sans size="medium">
                    {this.state.isFromPrice ? "From" : "Total"}
                  </Heading>
                  <Heading left mb="none" sans size="medium">
                    <ProductTotal
                      productPrice={p.priceText}
                      productId={p.id}
                      configuration={configuration}
                      isFrom={this.setIsFrom}
                    />
                  </Heading>
                </div>
                <Button
                  size="medium"
                  full
                  disabled={this.context.loading}
                  onClick={this.addToCart}
                >
                  {this.context.loading ? (
                    <Loading minHeight={false} />
                  ) : cartId ? (
                    siteConfig.updateCart
                  ) : (
                    siteConfig.addToCart
                  )}
                </Button>
                <BookContainer product={p} configuration={configuration} />
              </PFooter>
              <Copy size="small" html={siteConfig.threeD}></Copy>
              <hr />
              <Copy size="small" html={siteConfig.bespoke} />
              {p.shipping_info.value && (
                <>
                  <hr />
                  <Copy size="small">{p.shipping_info.value}</Copy>
                </>
              )}
              <WishlistContainer product={p} configuration={configuration} />
              <WishlistContainer
                icon={"hint"}
                text={siteConfig.hintText}
                modalTitle={siteConfig.hintHeadline}
                modalCopy={siteConfig.hintCopy}
                modalSuccess={siteConfig.hintSuccess}
                template="hint"
                product={p}
                configuration={configuration}
              />
            </div>
          </div>
        </div>
      </Section>,
      p.related.length ? (
        <Section mt="medium" mb="none" container="container-fluid">
          <div className="col-md-12 featured-cards-wrapper">
            <Heading uppercase sans center size="medium" mb="large">
              Other Products You Might Like
            </Heading>

            <Media
              query="(min-width: 1200px)"
              render={() => (
                <div className="row justify-content-center">
                  {p.related.map((r, index) => this.relatedCard(r, index))}
                </div>
              )}
            />

            <Media
              query="(min-width: 994px) and (max-width: 1199px)"
              render={() => (
                <Fragment>
                  {p.related.length > 4 ? (
                    <SliderWrapper>
                      {p.related.map((r, index) => this.relatedCard(r, index))}
                    </SliderWrapper>
                  ) : (
                    <div className="row justify-content-center">
                      {p.related.map((r, index) => this.relatedCard(r, index))}
                    </div>
                  )}
                </Fragment>
              )}
            />
            <Media
              query="(min-width: 768px) and (max-width: 993px)"
              render={() => (
                <Fragment>
                  {p.related.length > 3 ? (
                    <SliderWrapper>
                      {p.related.map((r, index) => this.relatedCard(r, index))}
                    </SliderWrapper>
                  ) : (
                    <div className="row justify-content-center">
                      {p.related.map((r, index) => this.relatedCard(r, index))}
                    </div>
                  )}
                </Fragment>
              )}
            />

            <Media
              query="(min-width: 576px) and (max-width: 767px)"
              render={() => (
                <Fragment>
                  {p.related.length > 2 ? (
                    <SliderWrapper>
                      {p.related.map((r, index) => this.relatedCard(r, index))}
                    </SliderWrapper>
                  ) : (
                    <div className="row justify-content-center">
                      {p.related.map((r, index) => this.relatedCard(r, index))}
                    </div>
                  )}
                </Fragment>
              )}
            />

            <Media
              query="(max-width: 575px)"
              render={() => (
                <Fragment>
                  {p.related.length > 1 ? (
                    <SliderWrapper>
                      {p.related.map((r, index) => this.relatedCard(r, index))}
                    </SliderWrapper>
                  ) : (
                    <div className="row justify-content-center">
                      {p.related.map((r, index) => this.relatedCard(r, index))}
                    </div>
                  )}
                </Fragment>
              )}
            />
          </div>
        </Section>
      ) : null
    ];
  }
}

export default RingContainer;
