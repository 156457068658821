import React from "react";
import { Link } from "react-router-dom";

const Footer = ({ footerMenu }) => {
  const { menu } = footerMenu;
  const mainFooterItems = [
    {
      icon: "icon icon-map",
      title: "Store Finder",
      link: "https://bash.com/store-finder"
    },
    {
      icon: "icon icon-phone",
      title: "021 938 1943",
      link: "tel:021 938 1943"
    },
    {
      icon: "icon icon-envelope",
      title: "JewelCustCare@tfg.co.za",
      link: "mailto: JewelCustCare@tfg.co.za"
    },
    {
      icon: "icon icon-envelope",
      title: "bespokedesigns@asj.co.za",
      link: "mailto: bespokedesigns@asj.co.za"
    }
  ];

  return (
    <div>
      <div style={{ display: "contents" }}>
        <div className="layout-spacer"></div>
        <div class="footer-layout">
          <div class="footer-layout-top-row">
            <div class="footer-layout-top__rowContent">
              <div class="footer-layout-top__rowContent__column">
                <nav class="footer-menu-nav-container ">
                  <ul class="footer-menu-nav_list">
                    {mainFooterItems.map((item, index) => {
                      return (
                        <li class="footer-menu-nav_list-item" key={index}>
                          <a
                            target="_blank"
                            href={item.link}
                            class="footer-menu-nav_list-item-link"
                            rel="noopener noreferrer"
                          >
                            <i class={`${item.icon}`}></i>
                            <span class="main-footer-menu-item-label">
                              {item.title}
                            </span>
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </nav>
              </div>
              <div
                class="footer-layout-top__rowContent__column--2"
                style={{ width: "100%" }}
              >
                <div class="accordionWrapper">
                  {menu &&
                    menu.menuItems &&
                    menu.menuItems.edges.map(({ node }, index) => {
                      return (
                        <div
                          class="footer-nav-accordion"
                          id="accordion-customer-service"
                          key={index}
                        >
                          <h4 class="accordion-title">
                            <button class="footer-nav-accordion-button">
                              {node.label}
                              {/* <svg
                          class="thefoschini-vtex-tfg-custom-components-2b5BfFsVwre_Qkm_Vog8x5 thefoschini-vtex-tfg-custom-components-1-x-accordionItemArrow thefoschini-vtex-tfg-custom-components-1-x-accordionItemArrow--tfg-footer-nav-accordion thefoschini-vtex-tfg-custom-components-3HRPmXs_nGyCSRQk1Yvul4 thefoschini-vtex-tfg-custom-components-1-x-chevronIcon thefoschini-vtex-tfg-custom-components-1-x-chevronIcon--tfg-footer-nav-accordion"
                          height="10"
                          width="18"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 18 10"
                          fill="none"
                        >
                          <path
                            d="M16.5 1L9 8.5L1.5 1"
                            stroke="#040404"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="thefoschini-vtex-tfg-custom-components-1-x-chevronPath thefoschini-vtex-tfg-custom-components-1-x-chevronPath--tfg-footer-nav-accordion"
                          ></path>
                        </svg> */}
                            </button>
                          </h4>
                          <div
                            class="footer-nav-accordion-content-container"
                            style={{ maxHeight: "224px" }}
                          >
                            <div class="footer-nav-accordion-item-content">
                              <nav>
                                <ul class="footer-accordion-list">
                                  {node.childItems.edges.map(
                                    ({ node }, index) => {
                                      return (
                                        <li
                                          class="footer-accordion-list-items"
                                          key={index}
                                        >
                                          {node.connectedNode &&
                                          node.connectedNode.node &&
                                          node.connectedNode.node.slug ? (
                                            <Link
                                              class="footer-accordion-list-items-link"
                                              to={`/${node.connectedNode.node.slug}`}
                                            >
                                              {node.label}
                                            </Link>
                                          ) : (
                                            <a
                                              rel="noopener noreferrer"
                                              target="_blank"
                                              href={node.url}
                                              class="footer-accordion-list-items-link"
                                            >
                                              <span class="footer-accordion-list-item-label">
                                                {node.label}
                                              </span>
                                            </a>
                                          )}
                                        </li>
                                      );
                                    }
                                  )}
                                </ul>
                              </nav>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
          <div class="footer-layout-bottom-row">
            <section class="footer-layout-bottom-row-container">
              <div class="footer-layout-bottom-row-content">
                <div
                  class="footer-bottom-left-wrapper"
                  style={{ width: "auto" }}
                >
                  <div class="footer-bottom-left-column">
                    <div
                      class="footer-bottom-left-column-child"
                      style={{ height: "auto" }}
                    >
                      <Link to="/home" class="footer-logo-link">
                        <span class="footer-logo">
                          <img
                            alt="American Swiss"
                            class="footer-logo-image"
                            data-src="https://cdn.tfgmedia.co.za/bash-assets/bash-logo.svg"
                            loading="lazy"
                            src="https://assets.bash.com/cms/americanswiss_store_logo_9443cc4ac8.png"
                          />
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div
                  class="flex-box-right-item-wrapper"
                  style={{ width: "auto" }}
                >
                  <div class="flex-box-right-item"></div>
                </div>
                <div
                  class="flex-box-right-item-wrapper"
                  style={{ width: "auto" }}
                >
                  <div class="footer-bottom-right">
                    <div
                      class="bottom-right-column-container"
                      style={{ height: "auto" }}
                    >
                      <div class="bottom-right-child-wrapper">
                        <div class="bottom-right-child-wrapper-layout">
                          <div
                            class="bottom-right-child-wrapper-layout-column"
                            style={{ width: "100%" }}
                          >
                            <div class="footer-socials-and-secureBy">
                              <div
                                class="footer-socials-and-secureBy-wrapper"
                                style={{ height: "100%" }}
                              >
                                <nav class="footer-social-nav">
                                  <ul class="footer-social-nav-items">
                                    <li class="footer-social-list-item">
                                      <a
                                        target="_blank"
                                        href="https://www.instagram.com/friendsofbash"
                                        class="footer-social-list-item-link"
                                        rel="noopener noreferrer"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          class="footer-social-list-item-link-icon"
                                        >
                                          <path d="M14.829 6.302c-.738-.034-.96-.04-2.829-.04s-2.09.007-2.828.04c-1.899.087-2.783.986-2.87 2.87-.033.738-.041.959-.041 2.828s.008 2.09.041 2.829c.087 1.879.967 2.783 2.87 2.87.737.033.959.041 2.828.041 1.87 0 2.091-.007 2.829-.041 1.899-.086 2.782-.988 2.87-2.87.033-.738.04-.96.04-2.829s-.007-2.09-.04-2.828c-.088-1.883-.973-2.783-2.87-2.87zm-2.829 9.293c-1.985 0-3.595-1.609-3.595-3.595 0-1.985 1.61-3.594 3.595-3.594s3.595 1.609 3.595 3.594c0 1.985-1.61 3.595-3.595 3.595zm3.737-6.491c-.464 0-.84-.376-.84-.84 0-.464.376-.84.84-.84.464 0 .84.376.84.84 0 .463-.376.84-.84.84zm-1.404 2.896c0 1.289-1.045 2.333-2.333 2.333s-2.333-1.044-2.333-2.333c0-1.289 1.045-2.333 2.333-2.333s2.333 1.044 2.333 2.333zm-2.333-12c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.958 14.886c-.115 2.545-1.532 3.955-4.071 4.072-.747.034-.986.042-2.887.042s-2.139-.008-2.886-.042c-2.544-.117-3.955-1.529-4.072-4.072-.034-.746-.042-.985-.042-2.886 0-1.901.008-2.139.042-2.886.117-2.544 1.529-3.955 4.072-4.071.747-.035.985-.043 2.886-.043s2.14.008 2.887.043c2.545.117 3.957 1.532 4.071 4.071.034.747.042.985.042 2.886 0 1.901-.008 2.14-.042 2.886z" />
                                        </svg>
                                        <span class="footer-social-iten-label">
                                          Instagram
                                        </span>
                                      </a>
                                    </li>

                                    <li class="footer-social-list-item">
                                      <a
                                        target="_blank"
                                        href="https://www.instagram.com/friendsofbash"
                                        class="footer-social-list-item-link"
                                        rel="noopener noreferrer"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          class="footer-social-list-item-link-icon"
                                        >
                                          <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm3 8h-1.35c-.538 0-.65.221-.65.778v1.222h2l-.209 2h-1.791v7h-3v-7h-2v-2h2v-2.308c0-1.769.931-2.692 3.029-2.692h1.971v3z" />
                                        </svg>
                                        <span class="footer-social-iten-label">
                                          facebook
                                        </span>
                                      </a>
                                    </li>
                                    <li class="footer-social-list-item">
                                      <a
                                        target="_blank"
                                        href="https://www.instagram.com/friendsofbash"
                                        class="footer-social-list-item-link"
                                        rel="noopener noreferrer"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          class="footer-social-list-item-link-icon"
                                        >
                                          <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
                                        </svg>
                                        <span class="footer-social-iten-label">
                                          Twitter
                                        </span>
                                      </a>
                                    </li>
                                  </ul>
                                </nav>
                              </div>
                              {/* <div
                                class="footer-socials-and-secureBy"
                                style={{ height: "100%" }}
                              >
                                <div class="secureBy-container">
                                  <div class="secureBy-wrapper">
                                    <p class="secureBy-text">
                                      This is a secure site
                                      <img
                                        class="secureBy-image"
                                        src="https://thefoschini.vtexassets.com/assets/vtex/assets-builder/thefoschini.store-theme/10.10.6/footer-images/digicert___ceb8b2253b09974cbbd86a90771de8a2.svg"
                                        alt="Digicert Image"
                                      />
                                    </p>
                                  </div>
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="footer-copyRight-container"
                      style={{ height: "auto" }}
                    >
                      <div class="footer-copyRight-wrapper">
                        <div class="footer-copyRight-wrapper-child">
                          <p class="footer-copyRight-text ">
                            © Copyright The Foschini Retail Group (Pty) Ltd.
                            <br />
                            All Rights Reserved. Foschini Retail Group (Pty) Ltd
                            is a registered credit provider (NCRCP36)
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
