import React, { useState, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import {
  HomeContainer,
  PageContainer,
  ProductSetupContainer,
  OrderConfirmation,
  OrderSummary,
  RangeContainer,
  BespokeContainer,
  AnnouncementContainer,
  TopNav,
  BottomNav
} from "./containers";
import ShopContainer from "./containers/Content/ShopContainer";
import OrderSnapscan from "./containers/Product/OrderSnapscan";
import Footer from "./components/Footer/Footer";
import { Loading } from "@lucio-erasmus/tfgj-components";
import { getMenu, getMainMenu, setWpMenu } from "./helpers/wp";

class GAListener extends React.Component {
  static contextTypes = {
    router: PropTypes.object
  };

  componentDidMount() {
    //this.sendPageView(this.context.router.history.location);
    //this.context.router.history.listen(this.sendPageView);
  }

  sendPageView(location) {
    //ReactGA.set({ page: location.pathname });
    //ReactGA.pageview(location.pathname);
  }

  render() {
    return this.props.children;
  }
}

export const App = () => {
  const [mainMenu, setMainMenu] = useState({ loading: true, menu: null });
  const [mainNavMenu, setMainNavMenu] = useState({ loading: true, menu: null });
  const [footerMenu, setFooterMenu] = useState({ loading: true, menu: null });

  useLayoutEffect(() => {
    getMenu().then(data => {
      setMainMenu({
        loading: false,
        menu: data
      });
    });
    getMainMenu().then(data => {
      setMainNavMenu({
        loading: false,
        menu: data
      });
    });

    setWpMenu().then(data => {
      setFooterMenu({
        loading: false,
        menu: data
      });
    })
  }, []);

  if (mainMenu.loading || mainNavMenu.loading ) {
    return <Loading />;
  }

  return [
    <BrowserRouter>
      <GAListener>
        <TopNav />
        <BottomNav menu={mainNavMenu.menu} />
        <Switch>
          <Route exact path="/" component={HomeContainer} />
          <Route exact path="/cart" component={OrderConfirmation} />
          {/* <Route exact path="/design-your-own-ring" component={DYORContainer} /> */}
          <Route
            exact
            path="/design-your-own-ring/bespoke-design-studio"
            component={BespokeContainer}
          />
          <Route exact path="/p/:savedId" component={ProductSetupContainer} />
          <Route
            exact
            path="/order/snapscan/:orderId"
            component={OrderSnapscan}
          />
          <Route exact path="/order/:orderId" component={OrderSummary} />
          <Route
            exact
            path="/range/:rangeId-:rangeSlug"
            component={RangeContainer}
          />
          <Route
            exact
            path="/product-category/:slug"
            component={RangeContainer}
          />
          <Route
            exact
            path="/product-category/:parent/:slug"
            component={RangeContainer}
          />
          <Route
            exact
            path="/product-category/:parent/:child/:slug"
            component={RangeContainer}
          />
          <Route
            exact
            path="/product/:productId-:productSlug/:cartId?"
            component={ShopContainer}
          />
          <Route
            exact
            path="/product/:productId-:productSlug/:cartId?"
            component={ShopContainer}
          />
          <Route exact path="/:slug" component={PageContainer} />
          <Route exact path="/:parent/:slug" component={PageContainer} />
        </Switch>
        <AnnouncementContainer />
        <Footer footerMenu={footerMenu} />
      </GAListener>
    </BrowserRouter>
  ];
};

export default App;
