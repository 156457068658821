import React, { Component } from "react";
import PropTypes from "prop-types";
import Media from "react-media";
import { graphql, compose } from "react-apollo";
import { gql } from "apollo-boost";
import { Redirect } from "react-router";
import { siteConfig } from "../../helpers/config";
import {
  Section,
  Border,
  Button,
  FormHeading,
  Heading,
  Loading,
  PDescription,
  ProductRow,
  Copy
} from "@lucio-erasmus/tfgj-components";
import { ImageBuilder } from "../../components";
import { FormContainer } from "../../containers";
import {
  formatPrice,
  getProductConfig,
  getConfigurationOptions
} from "../../helpers/utils";

const EntryTotal = props => {
  const { onSale, salesPrice, itemPrice } = props.total;

  if (onSale) {
    return (
      <React.Fragment>
        <div>
          <span class="sale">Was R{formatPrice(itemPrice)}</span>
        </div>{" "}
        <strong>Now R{formatPrice(salesPrice)}</strong>
      </React.Fragment>
    );
  }

  return <React.Fragment>R{formatPrice(itemPrice)}</React.Fragment>;
};

export const Total = props => {
  const { regularTotal, promotionTotal } = props.total;

  const discount =
    Number(regularTotal.replace(/ /g, "")) -
    Number(promotionTotal.replace(/ /g, ""));

  return (
    <React.Fragment>
      {discount > 0 && (
        <React.Fragment>
          <div className="d-flex justify-content-between">
            <Heading sans size="medium" weight="bold">
              Discount
            </Heading>
            <Heading sans size="medium" weight="bold">
              {`R ${formatPrice(discount)}`}
            </Heading>
          </div>
          <Border mb="large" />
        </React.Fragment>
      )}

      <div className="d-flex justify-content-between">
        <Heading sans size="medium" weight="bold">
          Order Total
        </Heading>
        <Heading sans size="medium" weight="bold">
          {`R ${promotionTotal}`}
        </Heading>
      </div>
    </React.Fragment>
  );
};

class OrderConfirmation extends Component {
  state = {
    loading: false,
    form: {
      delivery: "click"
    }
  };

  static contextTypes = {
    cart: PropTypes.func.isRequired,
    loading: PropTypes.func.isRequired,
    duplicateProduct: PropTypes.func.isRequired,
    removeFromCart: PropTypes.func.isRequired
  };

  editProduct = entry => {
    const {
      id,
      productId,
      product: { slug }
    } = entry;
    this.props.history.push(`/product/${productId}-${slug}/${id}`);
  };

  setValues = (name, value) => {
    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        [name]: value
      }
    }));
  };

  setAddress = address => {
    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        ...address.formattedAddressObject
      }
    }));
  };

  routeHome = entry => {
    this.props.history.push(`/`);
  };

  createOrder = (errors, values) => {
    this.setState({
      loading: true
    });

    this.props
      .createOrder({
        variables: {
          input: {
            ...values
          }
        }
      })
      .then(({ data }) => {
        this.setState({
          loading: false
        });

        const { payment_method } = data.createOrder;

        if (payment_method.external) {
          window.location.href = payment_method.redirect;
        } else {
          this.props.history.push(payment_method.redirect);
        }
      })
      .catch(err => {
        this.setState({
          error: err
        });
      });
  };

  render() {
    if (this.props.loading) return <Loading />;

    const { page: p } = this.props;

    const cart = this.context.cart();

    if (cart && cart.entries && cart.entries.length === 0) {
      return <Redirect to={"/"} />;
    }

    return [
      <Section mt="small">
        <div className="col-md-12">
          <div className="main__header">
            <Heading level={"1"} mb="small" size="large" uppercase>
              {p.title}
            </Heading>
          </div>
          <Copy html={p.content} />

          <FormHeading>Product Summary</FormHeading>

          {cart &&
            cart.entries.map(entry => {
              const product = getProductConfig(
                entry.configuration,
                getConfigurationOptions(entry.product.configuration.components)
              );

              if (entry.images && entry.images.length) {
                const [image, file] = entry.images;

                return (
                  <ProductRow
                    title={entry.product.name}
                    total={<EntryTotal total={entry.total} />}
                    image={`${process.env.REACT_APP_PRODUCT}${
                      file ? file._id : image._id
                    }`}
                    renderMeta={() => (
                      <PDescription>
                        {product
                          .filter(c => c.sku !== "UPLOAD-IMG")
                          .map(c => (
                            <div>
                              {c.group}: {c.value || c.name}
                              {c.font ? ` - ${c.font}` : null}{" "}
                              {c.languageLabel ? ` - ${c.languageLabel}` : null}
                            </div>
                          ))}
                      </PDescription>
                    )}
                    onDuplicate={() => this.context.duplicateProduct(entry.id)}
                    onRemove={() => this.context.removeFromCart(entry.id)}
                    onEdit={() => this.editProduct(entry)}
                  />
                );
              }
              return (
                <div key={entry.id}>
                  {entry.product.configuration &&
                  entry.product.configuration.id ? (
                    <ImageBuilder
                      productId={entry.product.configuration.id}
                      configuration={entry.configuration}
                      render={image => (
                        <ProductRow
                          title={entry.product.name}
                          total={<EntryTotal total={entry.total} />}
                          image={`${image}&dimW=205&dimH=205`}
                          renderMeta={() => (
                            <div>
                              {product
                                .filter(c => c.sku !== "UPLOAD-IMG")
                                .map(c => (
                                  <Copy size="small" mb="none">
                                    {c.group}: {c.value || c.name}{" "}
                                    {c.font ? ` - ${c.font}` : null}{" "}
                                    {c.languageLabel
                                      ? ` - ${c.languageLabel}`
                                      : null}
                                    {/*c.description ? (
                                      <PDescription html={`${c.description}`} />
                                    ) : null*/}
                                  </Copy>
                                ))}
                            </div>
                          )}
                          onDuplicate={() =>
                            this.context.duplicateProduct(entry.id)
                          }
                          onRemove={() => this.context.removeFromCart(entry.id)}
                          onEdit={() => this.editProduct(entry)}
                        />
                      )}
                    />
                  ) : (
                    <ProductRow
                      title={entry.product.name}
                      total={<EntryTotal total={entry.total} />}
                      renderMeta={() => (
                        <div>
                          {product
                            .filter(c => c.sku !== "UPLOAD-IMG")
                            .map(c => (
                              <Copy size="small" mb="none">
                                {c.group}: {c.value || c.name}{" "}
                                {c.font ? ` - ${c.font}` : null}{" "}
                                {c.languageLabel
                                  ? ` - ${c.languageLabel}`
                                  : null}{" "}
                                {/*c.description ? (
                                  <PDescription html={`${c.description}`} />
                                ) : null */}
                              </Copy>
                            ))}
                        </div>
                      )}
                      image={entry.product.featuredImage.url}
                      onDuplicate={() =>
                        this.context.duplicateProduct(entry.id)
                      }
                      onRemove={() => this.context.removeFromCart(entry.id)}
                      onEdit={() => this.editProduct(entry)}
                    />
                  )}
                </div>
              );
            })}
          <Border color="white" mt="small" mb="none" />
          <div className="d-flex justify-content-end">
            <Button onClick={this.routeHome} right>
              Add another
            </Button>
          </div>
        </div>

        <div className="col-md-12">
          <Border color="white" />
          <FormContainer
            formId="order"
            submitProcess={this.createOrder}
            renderSubmit={(onSubmit, form) => (
              <div style={{ position: "sticky", top: 0 }}>
                <Border mb="small" />
                <div className="d-flex justify-content-between">
                  <Heading sans size="medium" weight="bold">
                    Delivery Fee
                  </Heading>
                  <Heading sans size="medium" weight="bold">
                    {cart.shipping_price ? `R ${cart.shipping_price}` : "Free"}
                  </Heading>
                </div>
                <Border mb="large" />
                <Total total={cart.total} />

                <Border color="white" mb="none" />

                {process.env.REACT_APP_ANNOUNCEMENT && (
                  <label>{process.env.REACT_APP_ANNOUNCEMENT}</label>
                )}

                <Copy size="small" color="dark">
                  {siteConfig.finePrint}
                </Copy>
                <div className="d-sm-flex justify-content-between align-items-center">
                  <div className="form-check">
                    <input
                      name="agreement"
                      value={true}
                      className="form-check-input"
                      type="checkbox"
                    />
                    <label className="form-check-label" for="defaultCheck3">
                      Accept{" "}
                      <a
                        href={siteConfig.terms}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms &amp; Conditions
                      </a>
                    </label>
                  </div>

                  <Media query="(min-width: 576px)">
                    {matches =>
                      matches ? (
                        <Button
                          disabled={this.state.loading || this.context.loading}
                          right
                          iconAfter="arrow-right"
                          size="large"
                        >
                          {this.state.loading ? (
                            <Loading minHeight={false} />
                          ) : (
                            "Place Order"
                          )}
                        </Button>
                      ) : (
                        [
                          <Border mb="none" mt="none" color="white" />,
                          <Button
                            disabled={
                              this.state.loading || this.context.loading
                            }
                            full
                            iconAfter="arrow-right"
                            size="large"
                          >
                            {this.state.loading ? (
                              <Loading minHeight={false} />
                            ) : (
                              "Place Order"
                            )}
                          </Button>
                        ]
                      )
                    }
                  </Media>
                </div>

                {form.errors["agreement"] ? (
                  <Copy size="tiny" color="error">
                    Required Field
                  </Copy>
                ) : null}

                <Media
                  query="(min-width: 576px)"
                  render={() => <Border mb="large" mt="large" />}
                />
              </div>
            )}
          />
        </div>
      </Section>
    ];
  }
}

const PAGE_QUERY = gql`
  query getProduct($slug: String!) {
    page(slug: $slug) {
      id
      title
      content
      excerpt
      headline
    }
  }
`;

const withPage = graphql(PAGE_QUERY, {
  options: props => {
    return {
      variables: {
        slug: "cart"
      }
    };
  },
  props: ({ data }) => ({ ...data })
});

const CREATE_ORDER_MUTATION = gql`
  mutation createOrder($input: OrderInput!) {
    createOrder(input: $input) {
      _id
      redirect
      payment_method {
        gateway
        external
        redirect
      }
    }
  }
`;

const withCreateOrder = graphql(CREATE_ORDER_MUTATION, { name: "createOrder" });

export default compose(withPage, withCreateOrder)(OrderConfirmation);
