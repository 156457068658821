import React, { useLayoutEffect, useState } from "react";
import { graphql } from "react-apollo";
import { gql } from "apollo-boost";
import { Link } from "react-router-dom";


import {
  Section,
  Card,
  Loading,
  Copy,
  StoreFrontHeader,
  Border
} from "@lucio-erasmus/tfgj-components";
import { NewsletterContainer } from "../../containers";
import { getRange } from "../../helpers/wp";
import Seo from "../../components/Seo/Seo";


const RangeContainer = props => {
  const [mainMenu, setMainMenu] = useState({ loading: true, range: null });

  useLayoutEffect(() => {
    setMainMenu({
      loading: true,
      range: []
    });
    getRange(props.match.params.slug).then(data => {
      setMainMenu({
        loading: false,
        range: data
      });
    });
  }, [props.match.params.slug]);

  if (mainMenu.loading) {
    return <Loading />;
  }

  if (mainMenu.loading) return <Loading />;
  //const { ranges: featured } = props;
  const { range: p } = mainMenu;
  const { products = [] } = p;
  const title = [];

  if (p.parent) {
    title.push(p.parent.node.name);
  }

  title.push(p.name);
  const { metaDesc, fullHead } = p.seo;
  

  return (
    <>
      <Seo metaDesc={metaDesc} fullHead={fullHead} />;
      {
        <StoreFrontHeader
          image={
            p.DYORCategory &&
            p.DYORCategory.bannerImage &&
            p.DYORCategory.bannerImage.mediaItemUrl
              ? p.DYORCategory.bannerImage.mediaItemUrl
              : p.DYORCategory.bannerImageMobile &&
                p.DYORCategory.bannerImageMobile.mediaItemUrl
          }
          headline={
            (p.DYORCategory &&
              p.DYORCategory.headline &&
              p.DYORCategory.headline) ||
            title.join(" | ")
          }
          description={
            p.DYORCategory &&
            p.DYORCategory.description &&
            p.DYORCategory.description
          }
          color={p.DYORCategory && p.DYORCategory.color}
        />
      }
      {/* {p.DYORCategory && p.DYORCategory.headlineimage && (
        <div className="text-center" style={{ margin: "auto" }}>
          <img
            style={{ height: "100px", width: "auto" }}
            alt={p.headline ? p.headline : p.title}
            className="img-fluid pl-3 pr-3 py-3"
            src={p.DYORCategory.headlineimage.mediaItemUrl}
          />
        </div>
      )} */}
      {p.DYORCategory && p.DYORCategory.content && (
        <div className="container entry">
          <Copy html={p.DYORCategory.content} />
          <Border color="white" />
        </div>
      )}
      {products && (
        <Section rowClass={"row"} mb="none">
          {products.edges.map(({ node: prod }, index) => (
            <div
              key={index}
              className={
                products.edges.length === 5
                  ? "col-md-4 col-6"
                  : "col-md-3 col-6"
              }
            >
              <Link to={`/product/${prod.id}-${prod.slug}`}>
                <Card
                  vertical={true}
                  portrait={true}
                  key={prod.id}
                  title={prod.name}
                  description={
                    // prod.shortDescription &&
                    // prod.shortDescription +
                    prod.onSale
                      ? `<span class="sale">${
                          prod.variations ? "Was from" : "Was"
                        } ${prod.price}</span><br/><strong>${
                          prod.variations ? "Now from" : "Now"
                        } ${prod.salePrice}</strong>`
                      : prod.price
                      ? `From ${prod.price}`
                      : `${prod.variations ? "From" : ""} ${prod.price}`
                  }
                  eyeCatcher={
                    prod.productOptions.eyecatcher
                      ? prod.productOptions.eyecatcher
                      : null
                  }
                  image={
                    prod.image
                      ? {
                          url: prod.image.sourceUrl,
                          hotspot: ["50%", "50%"]
                        }
                      : null
                  }
                />
              </Link>
            </div>
          ))}
        </Section>
      )}
      <NewsletterContainer />
    </>
  );
};

const RANGE_QUERY = gql`
  query getRange {
    ranges {
      id
      slug
      title
      content
      excerpt
      headline
      eyeCatcher
      singleProduct {
        id
        slug
      }
      image {
        url
        hotspot
      }
    }
  }
`;

const withRange = graphql(RANGE_QUERY, {
  options: props => {
    return {
      variables: {
        id: props.match.params.rangeId
      }
    };
  },
  props: ({ data, post }) => ({ ...data })
});

export default withRange(RangeContainer);
