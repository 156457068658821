import React from "react";
import { Section, Copy, Button, Heading } from "@lucio-erasmus/tfgj-components";
import { FormContainer } from "..";
import { gql } from "apollo-boost";
import { Mutation } from "react-apollo";

const OrderError = ({ order, ...props }) => {
  return (
    <Mutation mutation={UPDATE_ORDER_PAYMENT}>
      {(updateOrderPayment, { data }) => (
        <Section mt="small">
          <div className="col-md-7">
            {order.reason ? (
              <div className="alert alert-danger" role="alert">
                {order.reason}
              </div>
            ) : null}
            <Copy mb={"none"}>Order Number: {order.orderNumber}</Copy>
            <Copy mb={"none"}>Date: {order.formatDate}</Copy>
            <Copy>Payment Method: {order.payment}</Copy>

            <Heading sans size="medium" weight="bold" mt={"small"} mb={"small"}>
              Order Total: R{order.totals}
            </Heading>
            <FormContainer
              formId="payment"
              submitProcess={(_, values) => {
                updateOrderPayment({
                  variables: { id: order._id, payment: values.payment }
                }).then(({ data }) => {
                  const { payment_method } = data.updateOrderPayment;

                  if (payment_method.external) {
                    window.location.href = payment_method.redirect;
                  } else {
                    props.history.push(payment_method.redirect);
                  }
                });
              }}
              renderSubmit={() => (
                <Button full iconAfter="arrow-right" size="large">
                  Place Order
                </Button>
              )}
            />
          </div>
          <div className="col-md-5">
            <aside class="help-box">
              <Heading display="medium">Customer Service </Heading>

              <Copy display="small">
                If you're having any difficulty completing your order, feel free
                to contact us and we'll gladly help.{" "}
                <strong>Call 021 938 1943.</strong>
              </Copy>
              <hr />
              <div class="footer__suffix-meta footer__suffix-meta--alt">
                <span class="footer__suffix-meta-copy">
                  This is a secure site
                </span>
                <span class="footer__suffix-meta-include">
                  <div>
                    <img
                      src="https://vml.tfgmedia.co.za/shared/img/digicert.png"
                      alt=""
                    />
                  </div>
                </span>
              </div>
            </aside>
          </div>
        </Section>
      )}
    </Mutation>
  );
};

const UPDATE_ORDER_PAYMENT = gql`
  mutation updateOrderPayment($id: ID!, $payment: Payment!) {
    updateOrderPayment(id: $id, payment: $payment) {
      _id
      redirect
      payment_method {
        gateway
        external
        redirect
      }
    }
  }
`;

export default OrderError;
