import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql } from "react-apollo";
import { gql } from "apollo-boost";
import { Redirect } from "react-router-dom";
import { Loading } from "@lucio-erasmus/tfgj-components";

class ProductSetupContainer extends Component {
  static contextTypes = {
    setConfig: PropTypes.func.isRequired
  };

  state = {
    redirect: false
  };

  componentDidUpdate(nextProps) {
    if (nextProps.saved !== this.props.saved) {
      const { saved } = this.props;
      this.context.setConfig(saved).then(() => {
        this.setState({
          redirect: true
        });
      });
    }
  }

  render() {
    const { loading, saved } = this.props;
    const { redirect } = this.state;
    if (loading || !redirect) return <Loading />;

    return (
      <Redirect to={`/product/${saved.product.id}-${saved.product.slug}`} />
    );
  }
}

const PRODUCT_QUERY = gql`
  query getSavedProduct($savedId: ID!) {
    saved(id: $savedId) {
      product {
        id
        slug
      }
      images {
        _id
      }
      imagePosition
      configuration {
        configId
        sku
        price
        id
        image
        type
        font
        language
        priceCategory
        specification
        exclusions {
          key
          values
        }
        description
        assetImage
        group
        name
        value
      }
    }
  }
`;

const withProduct = graphql(PRODUCT_QUERY, {
  options: props => {
    return {
      variables: {
        savedId: props.match.params.savedId
      }
    };
  },
  props: ({ data, post }) => ({ ...data })
});

export default withProduct(ProductSetupContainer);
