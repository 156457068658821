import React, { Component } from "react";
import PropTypes from "prop-types";
import { graphql, compose } from "react-apollo";
import { gql } from "apollo-boost";
import { StyleSheet, css } from "aphrodite";
import {
  variables,
  Section,
  Border,
  Heading,
  FormHeading,
  Hero,
  Loading,
  PDescription,
  ProductRow,
  Copy
} from "@lucio-erasmus/tfgj-components";
import { ImageBuilder } from "../../components";
import {
  formatPrice,
  getProductConfig,
  getConfigurationOptions
} from "../../helpers/utils";
import ShopContainer from "../Content/ShopContainer";
import OrderError from "./OrderError";
import { Total } from "./OrderConfirmation";

const EntryTotal = props => {
  const { onSale, salesPrice, itemPrice } = props.total;

  const itemPriceText = formatPrice(itemPrice);
  if (onSale) {
    return (
      <React.Fragment>
        <div>
          <span class="sale">Was R{formatPrice(itemPrice)}</span>
        </div>{" "}
        <strong>Now R{formatPrice(salesPrice)}</strong>
      </React.Fragment>
    );
  }

  return <React.Fragment>R{itemPriceText}</React.Fragment>;
};

class OrderSummary extends Component {
  static contextTypes = {
    cart: PropTypes.func.isRequired,
    duplicateProduct: PropTypes.func.isRequired,
    removeFromCart: PropTypes.func.isRequired
  };

  editProduct = entry => {
    const {
      id,
      productId,
      product: { slug }
    } = entry;
    this.props.history.push(`/product/${productId}-${slug}/${id}`);
  };

  routeHome = entry => {
    this.props.history.push(`/`);
  };

  createOrder = (errors, values) => {
    this.props.createOrder({
      variables: {
        input: {
          ...values
        }
      }
    });
  };

  render() {
    if (this.props.loading) return <Loading />;

    const { page: p, order } = this.props;

    const styles = StyleSheet.create({
      row: {
        marginTop: variables.spacing.space3,
        marginBottom: variables.spacing.space3
      }
    });

    if (
      order.status === "error" ||
      (order.status === "pending" && order.paymentType !== "INSTORE")
    ) {
      return <OrderError {...this.props} />;
    }

    return [
      <Hero
        title={p.headline || p.title}
        description={p.content}
        image={p.image}
        small
      />,
      <Section mt="small">
        {process.env.REACT_APP_ANNOUNCEMENT && (
          <div className="col-md-12">
            <div className="alert alert-warning mb-4">
              <Copy size="small" mb="none">
                {process.env.REACT_APP_ANNOUNCEMENT}
              </Copy>
            </div>
          </div>
        )}
        <div className="col-md-12">
          {order.paymentType === "SNAPSCAN" ? (
            <>
              <Heading sans size="medium" mb={"small"}>
                Thank you for your purchase!
              </Heading>
              <Copy mb={"large"}>
                Your Snapscan payment has been received.{" "}
              </Copy>
            </>
          ) : null}

          <Copy mb={"none"}>Order Number: {order.orderNumber}</Copy>
          <Copy mb={"none"}>Date: {order.formatDate}</Copy>
          <Copy>Payment Method: {order.payment}</Copy>
        </div>
        <div className="col-md-7">
          <FormHeading>Order Summary</FormHeading>
          <div>
            {order &&
              order.lineitems.map(entry => {
                const product = getProductConfig(
                  entry.configuration,
                  getConfigurationOptions(
                    entry.product.configuration.components
                  )
                );

                if (entry.images && entry.images.length) {
                  const [image, file] = entry.images;

                  return (
                    <ProductRow
                      title={entry.product.name}
                      total={<EntryTotal total={entry.total} />}
                      image={`${process.env.REACT_APP_PRODUCT}${
                        file ? file._id : image._id
                      }`}
                      renderMeta={() => (
                        <div>
                          {product
                            .filter(c => c.sku !== "UPLOAD-IMG")
                            .map(c => (
                              <Copy size="small" mb={"none"}>
                                {c.group}: {c.value || c.name}
                                {c.font ? ` - ${c.font}` : null}{" "}
                                {c.languageLabel
                                  ? ` - ${c.languageLabel}`
                                  : null}{" "}
                              </Copy>
                            ))}
                        </div>
                      )}
                    />
                  );
                }

                return (
                  <div>
                    {entry.product.configuration &&
                    entry.product.configuration.id ? (
                      <ImageBuilder
                        productId={entry.product.configuration.id}
                        configuration={product}
                        render={image => (
                          <ProductRow
                            title={entry.product.name}
                            renderMeta={() => (
                              <div>
                                {product
                                  .filter(c => c.sku !== "UPLOAD-IMG")
                                  .map(c => (
                                    <Copy size="small" mb={"none"}>
                                      {c.group}: {c.value || c.name}
                                      {c.font ? ` - ${c.font}` : null}{" "}
                                      {c.languageLabel
                                        ? ` - ${c.languageLabel}`
                                        : null}{" "}
                                    </Copy>
                                  ))}
                              </div>
                            )}
                            total={<EntryTotal total={entry.total} />}
                            image={`${image}&dimW=205&dimH=205`}
                          />
                        )}
                      />
                    ) : (
                      <ProductRow
                        title={entry.product.name}
                        total={<EntryTotal total={entry.total} />}
                        renderMeta={() => (
                          <div>
                            {product
                              .filter(c => c.sku !== "UPLOAD-IMG")
                              .map(c => (
                                <Copy size="small" mb={"none"}>
                                  {c.group}: {c.value || c.name}" "}
                                  {c.font ? ` - ${c.font}` : null}{" "}
                                  {c.description ? (
                                    <PDescription html={`${c.description}`} />
                                  ) : null}
                                </Copy>
                              ))}
                          </div>
                        )}
                        image={entry.product.featuredImage.url}
                      />
                    )}
                  </div>
                );
              })}
          </div>
          <div className={css(styles.row)}>
            <div className="d-flex justify-content-between mt-3">
              <Heading sans size="medium" weight="bold">
                Delivery Fee
              </Heading>
              <Heading sans size="medium" weight="bold">
                {order.shipping_price ? `R ${order.shipping_price}` : "Free"}
              </Heading>
            </div>
            <Border mb="small" />
            <Total
              total={{
                regularTotal: order.regularTotal,
                promotionTotal: order.totals
              }}
            />
          </div>
        </div>
        <div className="col-md-5">
          <FormHeading>Order Details</FormHeading>
          <Border color="white" mt="small" mb="small" />
          <Copy size={"small"} mb={"none"}>
            {order.details.name} {order.details.surname}
          </Copy>
          <Copy size={"small"} mb={"none"}>
            {order.details.email}
          </Copy>
          <Copy size={"small"} mb={"none"}>
            {order.details.phone}
          </Copy>

          {order.shipping_method.code !== "collection" ? (
            <React.Fragment>
              <FormHeading>Shipping Details</FormHeading>

              <Border color="white" mt="small" mb="small" />

              <Copy size={"small"} mb={"none"}>
                Delivery Method:{order.shipping_method.description}
              </Copy>

              <Copy size={"small"} mb={"none"}>
                {order.shippingAddress.firstName}{" "}
                {order.shippingAddress.lastName}
              </Copy>

              <Copy size={"small"} mb={"none"}>
                {order.shippingAddress.email}
              </Copy>
              <Copy size={"small"} mb={"none"}>
                {order.shippingAddress.phoneNumber}
              </Copy>

              <Copy size={"small"} mb={"none"}>
                {order.shippingAddress.addressLine1}
              </Copy>

              <Copy size={"small"} mb={"none"}>
                {order.shippingAddress.suburb}, {order.shippingAddress.city}
              </Copy>
              <Copy size={"small"} mb={"none"}>
                {order.shippingAddress.postalCode},{" "}
                {order.shippingAddress.province}
              </Copy>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <FormHeading>Store Details</FormHeading>
              <Border color="white" mt="small" mb="small" />
              <Copy size={"small"} mb={"small"}>
                {order.shipping_method.description}
              </Copy>
              <Copy size={"small"} mb={"none"}>
                {order.shippingAddress.store.name}
              </Copy>
              <Copy size={"small"} mb={"none"}>
                {order.shippingAddress.store.address}
              </Copy>
              <Copy size={"small"} mb={"none"}>
                {order.shippingAddress.store.email}
              </Copy>
            </React.Fragment>
          )}
        </div>
      </Section>
    ];
  }
}

const PAGE_QUERY = gql`
  ${ShopContainer.fragments.components}
  query getProduct($slug: String!, $orderId: ID!) {
    page(slug: $slug) {
      id
      title
      content
      excerpt
      headline
      image {
        url
        hotspot
        hasImage
      }
    }
    order(id: $orderId) {
      _id
      totals
      regularTotal
      orderNumber
      status
      reason
      shipping_price
      shippingAddress {
        firstName
        lastName
        phoneNumber
        email
        addressLine1
        suburb
        city
        postalCode
        province
        store {
          name
          address
          email
          branch
        }
      }
      shipping_method {
        code
        description
      }
      details {
        name
        surname
        email
        phone
      }
      store {
        name
        address
        email
        branch
      }
      payment
      paymentType
      formatDate
      lineitems {
        id
        productId
        imageUrl
        images {
          _id
          type
        }
        product {
          name
          slug
          featuredImage {
            url
            hotspot
            hasImage
          }
          configuration {
            id
            components {
              ...NameParts
              options {
                ...NameParts
                styles {
                  ...NameParts
                }
                items {
                  name
                  group
                  image
                  description
                  assetImage
                  id
                  sku
                  parentSku
                  price
                  priceCategory
                  exclusions {
                    key
                    values
                  }
                  styles {
                    ...NameParts
                  }
                }
              }
              styles {
                ...NameParts
              }
              items {
                name
                group
                image
                description
                assetImage
                id
                sku
                parentSku
                price
                priceCategory
                exclusions {
                  key
                  values
                }
                styles {
                  ...NameParts
                }
              }
            }
          }
        }
        configuration {
          configId
          sku
          price
          id
          image
          type
          font
          language
          priceCategory
          specification
          exclusions {
            key
            values
          }
          description
          assetImage
          group
          name
          value
        }
        total {
          discount
          itemPrice
          onSale
          salesPrice
        }
      }
    }
  }
`;

const withPage = graphql(PAGE_QUERY, {
  options: props => {
    return {
      variables: {
        slug: "thank-you",
        orderId: props.match.params.orderId
      }
    };
  },
  props: ({ data, post }) => ({ ...data })
});

export default compose(withPage)(OrderSummary);
