import React, { Component } from "react";
import PropTypes from "prop-types";

class FooterContainer extends Component {
  static contextTypes = {
    cart: PropTypes.func.isRequired
  };

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.setState({
        open: false
      });
      window.scrollTo(0, 0);
    }
  }

  render() {
    return (
      <footer className="footer">
        <div className="footer__content">
          <div className="footer__content-inner">
            <div className="footer__grid">
              <div className="footer__grid-item footer__grid-item--alt">
                <figure className="footer__locator-figure">
                  <a
                    className="footer__locator-link"
                    href="/browse/storeLocator.jsp"
                  >
                    <img
                      alt="Locate a store"
                      className="footer__locator-img"
                      src="https://vml.tfgmedia.co.za/shared/img/beyond-footer-store-locator.jpg"
                    />{" "}
                  </a>
                </figure>
                <div className="social">
                  <div className="social__list">
                    <div className="social__item">
                      <a
                        href="https://www.facebook.com/AmericanSwiss"
                        className="social__link social__link--facebook"
                        title="Facebook"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                      </a>
                    </div>
                    <div className="social__item">
                      <a
                        href="https://twitter.com/AmericanSwiss"
                        className="social__link social__link--twitter"
                        title="Twitter"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                      </a>
                    </div>
                    <div className="social__item">
                      <a
                        href="https://www.youtube.com/c/americanswiss"
                        className="social__link social__link--youtube"
                        title="YouTube"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                      </a>
                    </div>
                    <div className="social__item">
                      <a
                        href="https://www.instagram.com/americanswissza/"
                        className="social__link social__link--instagram"
                        title="Instagram"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer__grid-item">
                <a
                  className="footer__nav-link footer__nav-link--alt"
                  href="/browse/staticContent.jsp?pageName=EducationCentre"
                >
                  Education Centre{" "}
                </a>
                <a
                  className="footer__nav-link footer__nav-link--alt"
                  href="https://www.tfg.co.za/accounts"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Account{" "}
                </a>{" "}
                <a
                  className="footer__nav-link footer__nav-link--alt"
                  href="https://www.mytfgworld.com/browse/staticContent.jsp?pageName=How_To_Pay"
                >
                  Pay Your TFG Account
                </a>{" "}
                <a
                  className="footer__nav-link footer__nav-link--alt"
                  href="http://mytfg.tfguat.co.za/myaccount/login.jsp?pageName=rewardsLogin#sign-up-rewards"
                >
                  {" "}
                  Rewards{" "}
                </a>{" "}
                <a
                  className="footer__nav-link footer__nav-link--alt"
                  href="tel:+27219381943"
                >
                  {" "}
                  021 938 1943{" "}
                </a>{" "}
                <a
                  className="footer__nav-link footer__nav-link--alt"
                  href="mailto:bespokedesigns@asj.co.za"
                >
                  {" "}
                  bespokedesigns@asj.co.za{" "}
                </a>{" "}
              </div>
              <div className="footer__grid-item">
                <a
                  className="footer__nav-link"
                  href="https://www.americanswiss.co.za/browse/staticContent.jsp?pageName=aboutTfg"
                >
                  About TFG
                </a>{" "}
                <a
                  className="footer__nav-link"
                  href="https://www.tfgcareers.co.za/"
                >
                  TFG Careers
                </a>{" "}
                <a
                  className="footer__nav-link"
                  href="https://www.americanswiss.co.za/browse/staticContent.jsp?pageName=termsAndConditions"
                >
                  Terms &amp; Conditions
                </a>{" "}
                <a
                  className="footer__nav-link"
                  href="https://www.americanswiss.co.za/browse/staticContent.jsp?pageName=delivery"
                >
                  Delivery
                </a>{" "}
                <a
                  className="footer__nav-link"
                  href="https://www.americanswiss.co.za/browse/staticContent.jsp?pageName=Returns"
                >
                  Returns
                </a>{" "}
                <a
                  className="footer__nav-link"
                  href="https://www.americanswiss.co.za/browse/staticContent.jsp?pageName=faq"
                >
                  FAQs
                </a>
              </div>
              <div className="footer__grid-item footer-grid-item--desktop">
                <div className="order-status-widget">
                  <div className="order-status-widget__heading">
                    <h2 className="order-status-widget__title">Order Status</h2>
                  </div>
                  <div className="order-status-widget__content"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer__suffix">
          <div className="footer__suffix-inner">
            <div className="footer__suffix-meta-container">
              <div className="footer__suffix-meta footer__suffix-meta--alt">
                <span className="footer__suffix-meta-copy">
                  This is a secure site{" "}
                </span>
                <span className="footer__suffix-meta-include">
                  <div>
                    <img
                      src="https://vml.tfgmedia.co.za/shared/img/digicert.png"
                      alt=""
                    />
                  </div>
                </span>
              </div>
              <div className="footer__suffix-meta">
                <i className="footer__suffix-meta-icon" data-icon="visa"></i>
              </div>
              <div className="footer__suffix-meta">
                <i
                  className="footer__suffix-meta-icon"
                  data-icon="mastercard"
                ></i>
              </div>
              <div className="footer__suffix-meta">
                <i
                  className="footer__suffix-meta-icon"
                  data-icon="snapscan"
                ></i>
              </div>
              <div className="footer__suffix-meta">
                <i className="footer__suffix-meta-icon" data-icon="payu"></i>
              </div>
            </div>
            <div className="footer__suffix-copy">
              <div className="footer__suffix-logo">
                <i className="footer__suffix-logo-icon"></i>
              </div>
              <div className="footer__suffix-copy-inner">
                <span className="footer__suffix-copy-inner-text">
                  © Copyright The Foschini Group. All Rights Reserved.{" "}
                </span>{" "}
                <span className="footer__suffix-copy-inner-text">
                  {" "}
                  Foschini Retail Group (Pty) Ltd is a registered credit
                  provider (NCRCP36){" "}
                </span>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default FooterContainer;
