import React, { Component } from "react";
import { Motion, spring, presets } from "react-motion";
import {
  Copy,
  PHeader,
  PModule,
  Icon,
  PTabs,
  PTab,
  PBody,
  PDescription
} from "@lucio-erasmus/tfgj-components";

import {
  EngravableTranslate,
  Engravable,
  SingleComponent,
  SingleComponentText,
  StylesComponent,
  Selection
} from "../../components";

import ProductSelector from "./ProductSelector";
import OptionUpload from "./UploadImage";
import ContentModal from "../Product/ContentModal";
import { ProductContext } from "../Store/CartContext";
import AdditionalComponent from "./AdditionalComponent";
import { getConfigurationOptions, getProductConfig } from "../../helpers/utils";

class ComponentFinder extends Component {
  state = {
    active: null
  };

  mouseEnter = index => {
    this.setState({
      activeIndex: index
    });
  };

  mouseLeave = () => {
    this.setState({
      activeIndex: null
    });
  };

  getComponent = (type, props) => {
    const { p } = this.props;

    const configuration = this.getCurrentConfiguration();

    const product = getProductConfig(
      this.context.getProduct(),
      getConfigurationOptions(configuration)
    );

    const errors = this.context.getErrors();

    const onCloseProps = {};
    if (configuration.length > 1) {
      onCloseProps.onClose = () => this.setState({ active: null });
    }

    const modal = p.modals.find(m => m.component_id === props.sku);

    switch (type) {
      case "upload":
        return (
          <PModule parent close title={props.name}>
            <PBody hasPadding active>
              {props.description ? (
                <PDescription>{props.description}</PDescription>
              ) : null}
              <OptionUpload
                {...props}
                onLoad={file => this.props.addToCanvas(file)}
                userImg={this.props.userImg}
              />
              {modal ? (
                <ContentModal title={"How-to guide"} data={modal} />
              ) : null}
            </PBody>
          </PModule>
        );
      case "name":
      case "engrave":
        return (
          <Engravable
            subtitle={
              this.state.active &&
              this.state.active.parent &&
              this.state.active.parent.group
                ? this.state.active.parent.group
                : null
            }
            errors={errors[props.sku]}
            {...props}
            {...onCloseProps}
            selectedComponents={product}
          />
        );
      case "engraveTranslate":
        return (
          <EngravableTranslate
            subtitle={
              this.state.active &&
              this.state.active.parent &&
              this.state.active.parent.group
                ? this.state.active.parent.group
                : null
            }
            errors={errors[props.sku]}
            {...props}
            {...onCloseProps}
            selectedComponents={product}
          />
        );
      case "single":
        return (
          <SingleComponent
            subtitle={
              this.state.active &&
              this.state.active.parent &&
              this.state.active.parent.group
                ? this.state.active.parent.group
                : null
            }
            product={product}
            errors={errors[props.sku]}
            {...props}
            {...onCloseProps}
          />
        );
      case "singleText":
        return (
          <SingleComponentText
            subtitle={
              this.state.active &&
              this.state.active.parent &&
              this.state.active.parent.group
                ? this.state.active.parent.group
                : null
            }
            product={product}
            errors={errors[props.sku]}
            {...props}
            onClose={() => this.setState({ active: null })}
            componentDescription={p.component_description}
          />
        );
      case "styles":
        return (
          <StylesComponent
            subtitle={
              this.state.active &&
              this.state.active.parent &&
              this.state.active.parent.group
                ? this.state.active.parent.group
                : null
            }
            {...props}
            errors={errors}
            onSelect={c => this.setActive(c)}
            {...onCloseProps}
          />
        );
      case "additional":
      case "selection":
        return (
          <Selection
            subtitle={
              this.state.active &&
              this.state.active.parent &&
              this.state.active.parent.group
                ? this.state.active.parent.group
                : null
            }
            {...props}
            errors={errors[props.sku]}
            onSelect={c => this.setActive(c)}
            {...onCloseProps}
            product={product}
          />
        );
      default:
        let isDYOR = function(cat) {
          // checks whether product category is DYOR
          return cat.id === "79";
        };

        return (
          <PModule
            subtitle={null}
            title={
              p.categories.length && p.categories.some(isDYOR)
                ? p.name
                : "Personalise yours"
            }
            description={
              p.categories.some(isDYOR) && p.short_description
                ? p.short_description
                : null
            }
            parent
          >
            {configuration.map((c, index) => {
              let headingProps = {};

              if (c.type === "additional") {
                return (
                  <AdditionalComponent
                    key={index}
                    index={index}
                    active={index === this.state.activeIndex}
                    onAdditionalToggle={() =>
                      this.setState({ active: c, activeIndex: null })
                    }
                    config={c}
                    errors={errors}
                    product={product}
                    onEnter={() => this.mouseEnter(index)}
                    onLeave={() => this.mouseLeave()}
                  />
                );
              }

              return (
                <ProductSelector
                  key={index}
                  item={c}
                  product={product}
                  render={hasProduct => {
                    if (hasProduct) {
                      headingProps.icon = "done";
                    }

                    if (index === this.state.activeIndex) {
                      headingProps = {
                        hover: true,
                        icon: hasProduct ? "edit" : "angle-right"
                      };
                    }

                    return (
                      <PHeader
                        title={c.name}
                        onClick={() =>
                          this.setState({ active: c, activeIndex: null })
                        }
                        onEnter={() => this.mouseEnter(index)}
                        onLeave={() => this.mouseLeave()}
                        icon="angle-right"
                        {...headingProps}
                      >
                        <ProductSelector item={c} product={product} />
                        {errors[c.sku] ? (
                          <div className="w-100">
                            <Copy size="tiny" mb="none" color={"error"}>
                              {" "}
                              {typeof errors === "string"
                                ? errors
                                : "Review selection"}
                            </Copy>
                          </div>
                        ) : null}
                      </PHeader>
                    );
                  }}
                />
              );
            })}
          </PModule>
        );
    }
  };

  setActive = c => {
    this.setState({
      active: c
    });
  };

  getCurrentConfiguration = () => {
    const { configuration = [] } = this.props;
    return configuration.filter(c => {
      if (c.exclusions && c.exclusions.length) {
        const currentItem = this.context.getProduct();

        if (currentItem.length) {
          return c.exclusions.some(exclude => {
            const item = currentItem.find(i => i.sku === exclude.key);
            return !(item && exclude.values.includes(item.id));
          });
        } else {
          return false;
        }
      }

      return true;
    });
  };

  render() {
    const toCSS = () => ({ opacity: 1 });
    const configuration = this.getCurrentConfiguration();
    const { active } = this.state;

    const product = getProductConfig(
      this.context.getProduct(),
      getConfigurationOptions(configuration)
    );

    const withoutAdditionals = configuration.filter(
      p => p.type !== "additional"
    );

    if (active) {
      let next = null;
      let prev = null;

      if (active.parent) {
        const { parent } = active;
        if (parent.multiple) {
          const filteredOptions = active.parent.options.filter(o => {
            let excluded = true;

            if (o.exclusions && o.exclusions.length) {
              excluded = o.exclusions.some(e => {
                const match = product.some(p =>
                  p.sku === e.key ? e.values.includes(p.id) : false
                );

                return !match;
              });
            }

            return excluded;
          });

          const currentIndex = filteredOptions
            .map(c => c.sku)
            .indexOf(active.sku);

          const rightTabProps = {
            ml: currentIndex === 0 ? "auto" : null
          };

          if (currentIndex === 0 || currentIndex < filteredOptions.length - 1) {
            next = (
              <PTab
                onClick={() =>
                  this.setActive({
                    ...filteredOptions[currentIndex + 1],
                    parent
                  })
                }
                {...rightTabProps}
              >
                {filteredOptions[currentIndex + 1].group}
                <Icon name="arrow-right" />
              </PTab>
            );
          }

          if (currentIndex > 0) {
            prev = (
              <PTab
                onClick={() =>
                  this.setActive({
                    ...filteredOptions[currentIndex - 1],
                    parent
                  })
                }
              >
                <Icon name="arrow-left" />
                {filteredOptions[currentIndex - 1].group}
              </PTab>
            );
          }

          if (currentIndex === filteredOptions.length - 1) {
            next = (
              <PTab
                onClick={() => this.setState({ active: parent })}
                {...rightTabProps}
              >
                {parent.group}
                <Icon name="arrow-right" />
              </PTab>
            );
          }
        } else {
          next = (
            <PTab onClick={() => this.setState({ active: parent })} ml="auto">
              {parent.group}
              <Icon name="arrow-right" />
            </PTab>
          );
        }
      } else {
        const currentIndex = withoutAdditionals
          .map(c => c.sku)
          .indexOf(active.sku);
        const rightTabProps = {
          ml: currentIndex === 0 || active.type === "additional" ? "auto" : null
        };

        if (
          currentIndex === 0 ||
          currentIndex <= withoutAdditionals.length - 1
        ) {
          next = withoutAdditionals[currentIndex + 1] && (
            <PTab
              onClick={() =>
                this.setState({ active: withoutAdditionals[currentIndex + 1] })
              }
              {...rightTabProps}
            >
              {withoutAdditionals[currentIndex + 1].group}
              <Icon name="arrow-right" />
            </PTab>
          );
        }

        if (currentIndex > 0) {
          prev = (
            <PTab
              onClick={() =>
                this.setState({ active: withoutAdditionals[currentIndex - 1] })
              }
            >
              <Icon name="arrow-left" />
              {withoutAdditionals[currentIndex - 1].group}
            </PTab>
          );
        }

        if (
          (currentIndex === withoutAdditionals.length - 1 &&
            withoutAdditionals.length !== 1) ||
          active.type === "additional"
        ) {
          next = (
            <PTab
              onClick={() => this.setState({ active: null })}
              {...rightTabProps}
            >
              Overview
              <Icon name="arrow-right" />
            </PTab>
          );
        }
      }

      return (
        <div>
          {this.getComponent(active.type, active)}
          <PTabs hasBorder justifyContent="space-between">
            {prev}
            {next}
          </PTabs>
        </div>
      );
    }

    return (
      <Motion
        defaultStyle={{ opacity: -100 }}
        style={{ opacity: spring(0, presets.noWobble) }}
      >
        {value => {
          return (
            <div>
              <div className="box" style={toCSS(value.opacity)}>
                {configuration.length === 1
                  ? this.getComponent(
                      this.props.configuration[0].type,
                      this.props.configuration[0]
                    )
                  : this.getComponent(this.props.type, this.props)}
              </div>
              {this.props.children}
            </div>
          );
        }}
      </Motion>
    );
  }
}

ComponentFinder.contextType = ProductContext;

export default ComponentFinder;
