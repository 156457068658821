import React, { Component } from "react";
import {
  Copy,
  PBody,
  PDescription,
  PModule,
  PTabs,
  PTab,
  POptionsGrid,
  POptionImage
} from "@lucio-erasmus/tfgj-components";
import { ProductContext } from "../Store/CartContext";

class SingleComponent extends Component {
  state = {
    item: null,
    filter: null
  };

  onTabClick = tab => {
    this.setState({
      filter: tab
    });
  };

  onItemClick = item => {
    this.context.onProductAdd({
      ...item,
      sku: this.props.sku,
      group: this.props.group,
      price: item.price || this.props.price,
      type: this.props.type,
      parent: this.props.parentSku
    });
  };

  onDeleteClick = (item, active = {}) => {
    this.context.onProductDelete({
      ...item,
      sku: this.props.sku,
      parent: active.parentSku || null
    });
  };

  render() {
    const configuration = this.context.getProduct();

    const {
      name,
      description,
      className = "",
      groups = [],
      items = [],
      onClose,
      errors = [],
      subtitle,
      product = []
    } = this.props;
    const { filter } = this.state;
    let list = [];
    let selected;
    const activeItem = product.find(i => i.sku === this.props.sku);
    const config = configuration.reduce((result, item) => {
      if (item.specification) {
        Object.keys(item.specification).forEach(spec => {
          result[spec] = item.specification[spec];
        });
      }
      return result;
    }, {});

    if (groups && groups.length) {
      if (filter) {
        selected = filter;
        list = items.filter(i => i.group === filter);
      } else {
        list = items.filter(i => i.group === groups[0]);
        selected = groups[0];
      }
    } else {
      list = items;
    }

    return (
      <div className={className}>
        <PModule title={name} subtitle={subtitle} parent onClose={onClose}>
          <PBody active>
            {description && <PDescription hasPadding html={description} />}
            {groups && groups.length ? (
              <PTabs hasMargin>
                {groups.map((g, index) => (
                  <PTab
                    key={index}
                    onClick={() => this.onTabClick(g)}
                    active={g === selected}
                  >
                    {g}
                  </PTab>
                ))}
              </PTabs>
            ) : null}
            <POptionsGrid>
              {list.map(i => {
                let image = `${process.env.REACT_APP_BUILD}${
                  i.assetImage ? i.assetImage : i.image
                }`;

                if (
                  ["0.25", "0.5", "0.75", "1"].includes(i.id) &&
                  config.shape
                ) {
                  image = `/stones/${config.shape}-${i.id}.png`;
                }

                return (
                  <div
                    className={
                      className
                        ? className
                            .split(" ")
                            .map(r => `${r}--image`)
                            .join(" ")
                        : ""
                    }
                    key={`${config.shape}-${i.id}`}
                  >
                    <POptionImage
                      active={activeItem && activeItem.id === i.id}
                      onClick={() => this.onItemClick(i)}
                      onRemove={() => this.onDeleteClick(i, activeItem)}
                      imageWidth={i.imageWidth}
                      title={i.name}
                      image={image}
                    />
                  </div>
                );
              })}
            </POptionsGrid>
            {errors && errors.length > 0 ? (
              <div
                className="w-100"
                style={{
                  padding: "16px 16px 0",
                  borderTop: "1px solid #eaeaea"
                }}
              >
                <PDescription size="tiny">
                  <Copy size="tiny" mb="none" color={"error"}>
                    {" "}
                    {errors}{" "}
                  </Copy>
                </PDescription>
              </div>
            ) : null}
            {activeItem && activeItem.description ? (
              <div
                className="w-100"
                style={{
                  padding: "16px 16px 0",
                  borderTop: "1px solid #eaeaea"
                }}
              >
                {activeItem && activeItem.description && (
                  <PDescription size="tiny" html={activeItem.description} />
                )}
              </div>
            ) : null}
          </PBody>
        </PModule>
      </div>
    );
  }
}

SingleComponent.contextType = ProductContext;
export default SingleComponent;
