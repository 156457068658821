import React, { Fragment } from "react";
import Modal from "react-modal";
import {
  Button,
  ButtonText,
  Border,
  Hero,
  Copy,
  variables
} from "@lucio-erasmus/tfgj-components";

const styles = {
  closeButton: {
    backgroundColor: "#fff",
    color: "#000",
    border: "none",
    boxShadow: "none",
    padding: "0 15px"
  },
  closeHeader: {
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "flex-end"
  }
};

const customStyles = {
  overlay: {
    zIndex: 2000,
    backgroundColor: "rgba(0, 0, 0, 0.75)"
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 0,
    border: 0,
    borderRadius: 0,
    minWidth: "200px",
    maxWidth: "700px",
    height: "700px", // <-- This sets the height
    overlfow: "scroll"
  }
};

class ContentModal extends React.Component {
  constructor() {
    super();

    this.state = {
      modalIsOpen: false
    };

    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  closeModal() {
    this.setState({ modalIsOpen: false, sent: false });
  }

  render() {
    const { data: p } = this.props;

    return (
      <Fragment>
        <ButtonText
          onClick={this.openModal}
          size="medium"
          text
          iconBefore="info"
          mb={variables.spacing.space2}
        >
          {this.props.title}
        </ButtonText>
        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
        >
          <div style={styles.closeHeader}>
            <button style={styles.closeButton} onClick={this.closeModal}>
              <i className="icon icon-close" />
            </button>
          </div>
          <Hero modal title={p.headline ? p.headline : p.title} />

          <div className="container entry">
            <Copy html={p.content} />
            <Border color="white" />
            <Button onClick={this.closeModal}>Close</Button>
            <Border color="white" />
          </div>
        </Modal>
      </Fragment>
    );
  }
}

ContentModal.defaultProps = {
  buttonTitle: "View More",
  title: null,
  content: null
};
export default ContentModal;
