import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import {
  Button,
  Heading,
  Copy,
  Icon,
  PBody,
  PDescription,
  PModule,
  POptionsGrid,
  POptionText
} from "@lucio-erasmus/tfgj-components";
import { calculateCharLimit } from "../../helpers/utils";

class EngravableTranslate extends Component {
  constructor(props, context) {
    super(props, context);

    const product = this.context
      .getProduct()
      .find(i => i.sku === this.props.sku);

    this.state = {
      active: false,
      input: product ? product.value : "",
      font: product
        ? product.font
        : this.props.fonts.length
        ? this.props.fonts[0].id
        : null,
      notice: null,
      language: product ? product.language : null
    };
  }

  static contextTypes = {
    onProductAdd: PropTypes.func.isRequired,
    getProduct: PropTypes.func.isRequired
  };

  componentDidUpdate(prevProps) {
    if (prevProps.sku !== this.props.sku) {
      const product = this.context
        .getProduct()
        .find(i => i.sku === this.props.sku);

      this.setState({
        input: product ? product.value : "",
        font: product
          ? product.font
          : this.props.fonts.length
          ? this.props.fonts[0].id
          : null
      });
    }
  }

  firstUpperCase(input) {
    if (input === " ") return "";

    if (input.length) return input[0].toUpperCase() + input.substr(1);

    return input;
  }

  componentDidMount() {
    setTimeout(() => {
      this.input.focus();
    }, 200);
  }

  onEngraveChange = value => {
    const {
      limit,
      regEx,
      upcase = false,
      selectedComponents,
      type
    } = this.props;

    if (value.length > calculateCharLimit(selectedComponents, limit, type))
      return;

    let input = value;

    if (regEx) {
      switch (regEx) {
        case "keyboard": {
          input = input.replace(/[^A-Za-z0-9!-#%-*,-/:;?@[-]_{}\u2665]+/gi, "");
          break;
        }
        case "lettersOnly": {
          input = input.replace(
            /[^a-zA-Z0-9àáâãäèéêëìíîïòóôõöùúûü&\\-\s]+/g,
            ""
          );
          break;
        }
        default:
          break;
      }
    }

    if (upcase) {
      input = this.firstUpperCase(input);
    }

    this.setState({
      input
    });
  };

  applyTranslate = () => {
    this.context.onProductAdd({
      type: this.props.type,
      sku: this.props.sku,
      group: this.props.group,
      value: this.state.input,
      font: this.state.font,
      language: this.state.language
    });
  };

  selectFont = id => {
    this.setState({ font: id }, () => this.onEngraveChange(this.state.input));
  };

  selectLanguage = code => {
    this.setState({ language: code });
  };

  addChar = char => {
    const currentInput = this.state.input;

    const start = this.input.selectionStart;

    const newInput = [
      currentInput.slice(0, start),
      char,
      currentInput.slice(start)
    ].join("");
    this.onEngraveChange(newInput);

    this.input.focus();
    this.input.setSelectionRange(start, start);
  };

  renderInput() {
    const {
      name,
      limit,
      fonts,
      specialChars,
      languages,
      description,
      onClose,
      selectedComponents,
      type
    } = this.props;
    const { input, font, language } = this.state;

    const charLimit = calculateCharLimit(selectedComponents, limit, type);

    return (
      <Fragment>
        <PModule title={name} parent onClose={onClose}>
          <PBody active hasPadding>
            {description && <PDescription html={description} />}

            <input
              className="form-control"
              ref={node => (this.input = node)}
              onChange={e => this.onEngraveChange(e.target.value)}
              value={this.state.input}
            />
            <Copy size="tiny">
              {charLimit - input.length} character(s) remaining
            </Copy>
            {fonts && fonts.length > 1 ? (
              <div className="mt-3">
                <Heading color={"base"} uppercase sans size={"tiny"} mb="tiny">
                  Select a Font
                </Heading>
                <POptionsGrid>
                  {fonts &&
                    fonts.map((i, index) => (
                      <POptionText
                        key={index}
                        active={font ? i.id === font : false}
                        onClick={() => this.selectFont(i.id)}
                      >
                        {i.title}
                      </POptionText>
                    ))}
                </POptionsGrid>
              </div>
            ) : null}
            {specialChars && specialChars.length > 0 && (
              <div className="mt-3">
                <Heading color={"base"} uppercase sans size={"tiny"} mb="tiny">
                  Special Characters
                </Heading>
                <POptionsGrid>
                  {specialChars.map((i, index) => (
                    <POptionText
                      key={index}
                      onClick={() => this.addChar(i.char)}
                    >
                      <Icon name={i.name} />
                    </POptionText>
                  ))}
                </POptionsGrid>
              </div>
            )}
            {languages && languages.length && (
              <div className="mt-3">
                <Heading color={"base"} uppercase sans size={"tiny"} mb="tiny">
                  Translate
                </Heading>
                <POptionsGrid>
                  {languages.map((l, index) => (
                    <POptionText
                      key={index}
                      active={language ? l.code === language : false}
                      onClick={() => this.selectLanguage(l.code)}
                    >
                      {l.language}
                    </POptionText>
                  ))}
                </POptionsGrid>
              </div>
            )}

            {this.state.input.length > 0 ? (
              <div className="mt-3">
                <Copy
                  size="tiny"
                  html="
                  If you believe that your text has not been accurately
                  converted, please email us a jpeg of the translation on:
                  <a href='mailto:bespokedesign@americanswiss.co.za' tile='Email us' target='_blank' rel='noopener noreferrer'>bespokedesign@americanswiss.co.za"
                />
                <Button
                  size="small"
                  full
                  onClick={() => this.applyTranslate(this.state.input)}
                >
                  Apply
                </Button>
              </div>
            ) : null}
          </PBody>
        </PModule>
      </Fragment>
    );
  }

  render() {
    return this.renderInput();
  }
}

EngravableTranslate.defaultProps = {
  fonts: []
};

export default EngravableTranslate;
